import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader/TopHeader";

import config from "../config";
import url from "../config/axios";

const TermsConditions = () => {
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  const [Terms, setTerms] = useState("");

  useEffect(() => {
    url
      .get(`page/id?page=terms&consumerId=${config.consumerId}`)
      .then(async (res) => {
        // console.log(res);
        if (res.data.Message === "Success") {
          setTerms(res.data.data.body);
        } else {
        }
      });
  }, [chainID]);

  return (
    <>
      <TopHeader pageName={"Terms & Conditions"} />
      <div
        className="container"
        style={{ maxWidth: "940px", overflow: "hidden" }}>
        <div style={{ fontFamily: "Oswald" }}>
          {/* <h3 style={{ color: "var(--primary)" }}>Terms &#38; Conditions</h3> */}
          <h6 className="mt-2 mb-4 ">
            {/* Subtitle :{" "} */}
            <span
              className="SubtitlePP-TC"
              style={{ fontSize: "16px", fontWeight: "400" }}>
              DISCLAIMERS & ACKNOWLEDGMENT OF FEDERAL LAW
            </span>
          </h6>
          <div
            className="phoneTnB"
            dangerouslySetInnerHTML={{ __html: Terms }}
          />
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
