import React, { useEffect, useState, useContext } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import url from "../../config/axios";
import { firebase } from "../../config/firebase";
import googleIcon from "../../images/grommet-icons_google (1).png";
import eyeHidden from "../../images/hidden (1).png";
import strain from "../../images/leftStrain.png";
import eye from "../../images/visibility_black_24dp (4) 1.png";
import mobileOtpSend from "../../Icons/Mobile OTP modal.svg";
import verificationCode from "../../Icons/VerificationCode.svg";
import successImage from "../../Icons/SuccessModal.png";
import warningModal from "../../Icons/warningModal.svg";
import clip from "../../Icons/LS-left-Leaf.svg";
// import Timer from "./Timer";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import "./LSModal.css";
import "react-phone-number-input/style.css";
import { ChatContext } from "../../Contexts/ChatContext";
import { parsePhoneNumberFromString } from "libphonenumber-js";
let logo = require("../../images/" + config.logo);

function LSModal({ lsDisplay, helpDis, setProfile }) {
  const { setLoginModal, setUserData } = useContext(ChatContext);

  const uid = JSON.parse(localStorage.getItem("user"));
  if (uid === null) {
    localStorage.removeItem("user");
  }

  const field1 = React.useRef();
  const field2 = React.useRef();
  const field3 = React.useRef();
  const field4 = React.useRef();
  const field5 = React.useRef();
  const field6 = React.useRef();

  let history = useHistory();

  const [email, setEmail] = useState(true);
  const [emailS, setEmailS] = useState(false);
  const [emailF, setEmailF] = useState("");
  const [reset, setReset] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [pwd, setPwd] = useState(false);
  const [password, setPassword] = useState("");
  // const [timer, setTimer] = useState(false);
  const [showpass, setShowPass] = useState(false);
  const [numberVal, setnumberVal] = useState(true);
  const [emailVal, setEmailVal] = useState(false);
  const [missEmailVal, setMissEmailVal] = useState(false);
  const [otpVal, setOtpVal] = useState(false);
  // const [checkValMail, setCheckValMail] = useState(false);
  const [headLogo, setheadLogo] = useState([]);
  // const [firebaseToken, setFirebaseToken] = useState("");
  const [loginbtn, setLoginbtn] = useState(true);
  const [signupbtn, setSignupbtn] = useState(false);
  const [showLoginNsignupbar, setShowLoginNsignupbar] = useState(true);
  const [resetOTP, setResetOTP] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [signup, setSignup] = useState(false);
  const [phoneLogin, setPhoneLogin] = useState(false);
  const [afterPhoneOtp, setAfterPhoneOtp] = useState(false);
  const [afterFBorG, setAfterFBorG] = useState(false);

  //signInWithEmailAndPassword
  const [Semail, setSemail] = useState("");
  const [SPassword, setSPassword] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  //CreateAccountWithEmail
  const [Ename, setEName] = useState("");
  const [Eemail, setEemail] = useState("");
  const [EphoneNumber, setEphoneNumber] = useState("");
  const [EcountryCode, setEcountryCode] = useState("");
  const [Egender, setEGender] = useState("");
  const [Edate, setEDate] = useState("");
  const [EEMLN, setEMLN] = useState("");
  const [EMLED, setEMLED] = useState("");
  const [EDLN, setEDLN] = useState("");
  const [EPassword, setEPassword] = useState("");
  const [EProfessional, setEProfession] = useState("");
  const [EbtnLoad, setEBtnLoad] = useState(false);
  const [showEpassword, setShowEpassword] = useState(false);

  //CreateAccountWithGoogle
  const [Gname, setGName] = useState("");
  const [GphoneNumber, setGphoneNumber] = useState("");
  const [GcountryCode, setGcountryCode] = useState("");
  const [Ggender, setGGender] = useState("");
  const [Gdate, setGDate] = useState("");
  const [GEMLN, setGMLN] = useState("");
  const [GMLED, setGMLED] = useState("");
  const [GDLN, setGDLN] = useState("");
  const [GPassword, setGPassword] = useState("");
  const [GProfessional, setGProfession] = useState("");
  const [GGmail, setGGmail] = useState("");
  const [Gtoken, setGtoken] = useState("");
  const [GbtnLoad, setGBtnLoad] = useState(false);
  const [showGpassword, setShowGpassword] = useState(false);

  //CreateAccountWithPhone
  const [Pname, setPName] = useState("");
  const [Pemail, setPemail] = useState("");
  const [value, setValue] = useState("");
  const [PcountryCode, setPcountryCode] = useState("");
  const [Pgender, setPGender] = useState("");
  const [Pdate, setPDate] = useState("");
  const [PEMLN, setPMLN] = useState("");
  const [PPMLED, setPMLED] = useState("");
  const [PDLN, setPDLN] = useState("");
  const [PPassword, setPPassword] = useState("");
  const [PProfession, setPProfession] = useState("");
  const [PbtnLoad, setPBtnLoad] = useState(false);
  const [Ptoken, setPtoken] = useState("");
  const [showPpassword, setShowPpassword] = useState(false);

  //resetPassword
  const [Remail, setRemail] = useState("");
  const [RsendMail, setRsendMail] = useState("");
  const [Rpassword, setRpassword] = useState("");
  const [RCpassword, setRCpassword] = useState("");
  const [Rtoken, setRtoken] = useState("");

  useEffect(() => {
    let abc = String(EphoneNumber);
    const parsedPhoneNumber = parsePhoneNumberFromString(abc);
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.countryCallingCode;
      setEcountryCode(countryCode);
    } else {
      console.log("Invalid phone number");
    }
  }, [EphoneNumber]);

  useEffect(() => {
    let abc = String(GphoneNumber);
    const parsedPhoneNumber = parsePhoneNumberFromString(abc);
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.countryCallingCode;
      setGcountryCode(countryCode);
    } else {
      console.log("Invalid phone number");
    }
  }, [GphoneNumber]);

  useEffect(() => {
    let abc = String(value);
    const parsedPhoneNumber = parsePhoneNumberFromString(abc);
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.countryCallingCode;
      setPcountryCode(countryCode);
    } else {
      console.log("Invalid phone number");
    }
  }, [value]);

  const resetPass = () => {
    let Rbody = {
      identifier: Remail,
    };

    url
      .post(`/v1/auth/forgot-password-firebase-and-infra`, Rbody)
      .then(async (res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          resetPasswordOTP();
        } else {
        }
      })
      .catch((e) => {
        console.log(e.response);

        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: e.response.data.data.message,
        });
      });
  };

  const confirmRcode = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(Remail, RsendMail);
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult();
      // console.log(
      //   "id tocken is>>>>>>>>>>>>>>>>>>>>>>>>>> google>>>>>>>>>>.",
      //   JSON.stringify(idTokenResult.token)
      // );
      setRtoken(idTokenResult.token);
      passwordNew();
    } catch (e) {
      ErrorMessageModal("Whoops!", e.message, "OK");
    }
  };

  const ConfirmPassword = () => {
    let Cbody = {
      firebaseToken: Rtoken,
      newPassword: RCpassword,
    };

    url
      .put(`/v1/auth/update-password-firebase-and-infra`, Cbody)
      .then(async (res) => {
        //  console.log(res);
        if (res.data.Message === "Success") {
          ConfirmPass();

          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Password changed successfully",
          });
        } else {
        }
      })
      .catch((e) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: !e.response.data.data.message
            ? e.response.data.data["body.newPassword"]
            : e.response.data.data.message,
        });
      });
  };

  function isOver18FromNow(dateOfBirth) {
    const age = moment().diff(dateOfBirth, "years");
    return age >= 18;
  }

  const CreateAccountWithEmail = () => {
    const isUserOver18FromNow = isOver18FromNow(Edate);
    if (!isUserOver18FromNow) {
      return Swal.fire(
        "Missing",
        "Age should be 18 years or greater.",
        "warning"
      );
    }
    const dobDate = moment(EMLED, "YYYY-MM-DD");
    const now = moment();
    if (!dobDate.isAfter(now)) {
      return Swal.fire(
        "Missing",
        "Enter valid medical license date  ",
        "warning"
      );
    }
    // console.log(EphoneNumber);

    let Ebody = {
      name: Ename,
      email: Eemail,
      password: EPassword,
      medicalLicense: EEMLN,
      mlExpiry: EMLED,
      dob: Edate,
      gender: Egender,
      profession: EProfessional,
      mobileNo: EphoneNumber,
      drivingLicense: EDLN,
      countryCode: EcountryCode,
    };

    // return console.log(Ebody);

    if (!Ebody?.name)
      return Swal.fire("Missing", "User Name must Required", "warning");
    else if (!Ebody?.email)
      return Swal.fire("Missing", "Email must Required", "warning");
    else if (!Ebody?.mobileNo)
      return Swal.fire("Missing", "Mobile Number must Required", "warning");
    else if (!Ebody?.profession)
      return Swal.fire("Missing", "profession must Required", "warning");
    else if (!Ebody?.gender)
      return Swal.fire("Missing", "Gender must Required", "warning");
    else if (!Ebody?.dob)
      return Swal.fire("Missing", "Date of birth must Required", "warning");
    else if (!Ebody?.password)
      return Swal.fire("Missing", "password must Required", "warning");
    else if (!Ebody?.medicalLicense)
      return Swal.fire("Missing", "Medical License must Required", "warning");
    else if (!Ebody?.mlExpiry)
      return Swal.fire(
        "Missing",
        "Medical License Expire Date must Required",
        "warning"
      );
    else if (!Ebody?.drivingLicense)
      return Swal.fire("Missing", "Driving License must Required", "warning");
    else {
      setEBtnLoad(true);
      url
        .post(`/v1/auth/firebase-and-infra`, Ebody)
        .then(async (res) => {
          //  console.log(res);
          if (res.data.Message === "Success") {
            localStorage.setItem(
              "Etoken",
              JSON.stringify(res?.data?.data?.token)
            );
            localStorage.setItem(
              "user",
              JSON.stringify([res?.data?.data?.userInfo])
            );
            setUserData([res?.data?.data?.userInfo]);
            // handleNotification([EAccBody])
            lsDisplay(false);
            history.push("/");
          } else {
            //console.log("abc");
            setEBtnLoad(false);
          }
        })
        .catch((e) => {
          setEBtnLoad(false);
          console.log(e.response.data.data["body.password"]);

          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "warning",
            title: !e.response.data.data.message
              ? e.response.data.data["body.password"]
                ? e.response.data.data["body.password"]
                : e.response.data.data["body.email"]
              : e.response.data.data.message,
          });
        });
    }
  };

  const googlefirbaseLogin = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let response = await firebase
          .auth()
          .signInWithCredential(result.credential);

        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        await confirmContinueWithFirebaseLgoin(
          idTokenResult.token,
          idTokenResult.claims.email
        );
      })
      .catch((error) => {});
  };

  const confirmContinueWithFirebaseLgoin = async (ftoken, femail) => {
    let googledata = {
      identifier: femail,
      firebaseToken: ftoken,
    };
    console.log(googledata);
    url
      .put("/v1/auth/continue-with-firebase", googledata)
      .then(async (res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          if (!res.data.data.isSignedUp) {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "You dont have an Account. Please Register",
            });
          } else {
            localStorage.setItem(
              "user",
              JSON.stringify([res?.data?.data?.userInfo])
            );
            setUserData([res?.data?.data?.userInfo]);
            localStorage.setItem(
              "Etoken",
              JSON.stringify(res?.data?.data?.token)
            );
            lsDisplay(false);
            history.push("/");
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Login sucessfully",
            });
          }
        } else {
        }
      })
      .catch((e) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: e.response.data.data.message,
        });
      });
  };

  const googlefirbase = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        await firebase.auth().signInWithCredential(result.credential);
        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        await confirmContinueWithFirebase(
          idTokenResult.token,
          idTokenResult.claims.email
        );
      })
      .catch((error) => {});
  };

  const confirmContinueWithFirebase = async (ftoken, femail) => {
    let googledata = {
      identifier: femail,
      firebaseToken: ftoken,
    };
    console.log(googledata);
    url
      .put("/v1/auth/continue-with-firebase", googledata)
      .then(async (res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          if (!res.data.data.isSignedUp) {
            setGtoken(res.data.data.token);
            setGGmail(femail);
            //  setPtoken(res.data.data.token)
            AfterFBorGSection();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "You have Already Register. Please Login",
            });
          }
        } else {
        }
      })
      .catch((e) => {
        console.log(e.response);
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: e.response.data.data.message,
        });
      });
  };

  const CreateAccountWithGoogle = () => {
    const isUserOver18FromNow = isOver18FromNow(Gdate);
    if (!isUserOver18FromNow) {
      return Swal.fire(
        "Missing",
        "Age should be 18 years or greater.",
        "warning"
      );
    }
    const dobDate = moment(GMLED, "YYYY-MM-DD");
    const now = moment();
    if (!dobDate.isAfter(now)) {
      return Swal.fire(
        "Missing",
        "Enter valid medical license date  ",
        "warning"
      );
    }

    let Gbody = {
      token: Gtoken,
      name: Gname,
      email: GGmail,
      password: GPassword,
      medicalLicense: GEMLN,
      mlExpiry: GMLED,
      dob: Gdate,
      gender: Ggender,
      profession: GProfessional,
      mobileNo: GphoneNumber,
      drivingLicense: GDLN,
      countryCode: GcountryCode,
    };

    // return console.log(Gbody);

    if (!Gbody?.name)
      return Swal.fire("Missing", "User Name must Required", "warning");
    else if (!Gbody?.email)
      return Swal.fire("Missing", "Email must Required", "warning");
    else if (!Gbody?.mobileNo)
      return Swal.fire("Missing", "Mobile Number must Required", "warning");
    else if (!Gbody?.profession)
      return Swal.fire("Missing", "profession must Required", "warning");
    else if (!Gbody?.gender)
      return Swal.fire("Missing", "Gender must Required", "warning");
    else if (!Gbody?.dob)
      return Swal.fire("Missing", "Date of birth must Required", "warning");
    else if (!Gbody?.password)
      return Swal.fire("Missing", "password must Required", "warning");
    else if (!Gbody?.medicalLicense)
      return Swal.fire("Missing", "Medical License must Required", "warning");
    else if (!Gbody?.mlExpiry)
      return Swal.fire(
        "Missing",
        "Medical License Expire Date must Required",
        "warning"
      );
    else if (!Gbody?.drivingLicense)
      return Swal.fire("Missing", "Driving License must Required", "warning");
    else {
      setGBtnLoad(true);
      url
        .post(`/v1/auth/sign-up-presigned`, Gbody)
        .then(async (res) => {
          //  console.log(res);
          if (res.data.Message === "Success") {
            localStorage.setItem(
              "Etoken",
              JSON.stringify(res?.data?.data?.token)
            );
            localStorage.setItem(
              "user",
              JSON.stringify([res?.data?.data?.userInfo])
            );
            setUserData([res?.data?.data?.userInfo]);
            // handleNotification([EAccBody])
            lsDisplay(false);
            history.push("/");
          } else {
            setGBtnLoad(false);
          }
        })
        .catch((e) => {
          console.log(e.response);
          setGBtnLoad(false);

          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "warning",
            title: e.response.data.data.message,
          });
        });
    }
  };

  const signInWithEmailAndPasswordFirebase = async () => {
    if (!emailF) {
      setMissEmailVal(true);
      setEmailVal(false);
    } else if (!Semail) {
      setEmailVal(true);
      setMissEmailVal(false);
    }
    // return console.log(Semail,SPassword);
    else {
      try {
        await firebase.auth().signInWithEmailAndPassword(Semail, SPassword);
        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        console.log(
          "id tocken is>>>>>>>>>>>>>>>>>>>>>>>>>> google>>>>>>>>>>.",
          JSON.stringify(idTokenResult.token)
        );
        getlogin(idTokenResult.token);
      } catch (e) {
        console.log(e);
        ErrorMessageModal("Whoops!", "Invalid email or password.", "OK");
      }
    }
  };

  const getlogin = (t) => {
    let ELBody = {
      identifier: Semail,
      password: SPassword,
      firebaseToken: t,
    };
    // return console.log(ELBody);
    url
      .post("/v1/auth/login", ELBody)
      .then((res) => {
        if (res.data.Message === "Success") {
          localStorage.setItem(
            "Etoken",
            JSON.stringify(res?.data?.data?.token)
          );
          localStorage.setItem(
            "user",
            JSON.stringify([res?.data?.data?.userInfo])
          );

          lsDisplay(false);
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Account successfully Login",
          });
        } else {
        }
      })
      .catch((e) => {
        console.log(e);

        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: e.response.data.data.message,
        });
      });
  };

  useEffect(() => {
    let messaging = new Promise((rej, res) => {});
    if (firebase?.messaging?.isSupported()) {
      messaging = firebase?.messaging();
      messaging
        ?.getToken({
          vapidKey:
            "BD6VIVynX7yEy9fasP_qc5ON_Ut0oIIibX44bX5ryBxlGIygx7uLenG5VS1zIMOoKuQL5qV4bBY0Fx-pe3VcBBI",
        })
        .then((currentToken) => {
          //setFirebaseToken(currentToken);
          if (currentToken) {
          } else {
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      messaging.getToken = () => {};
      messaging.onMessage = () => {};
    }

    url.get(`/v1/general-settings/info`).then((res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setheadLogo(res?.data?.data?.media?.mainLogoFileSource);
        if (!res?.data?.data?.media?.mainLogoFileSource) setIsLoaded(true);
      } else {
        // alert("Not", res.data.data);
      }
    });
  }, []);

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
          console.log("Recaptca varified:", response);
        },
        defaultCountry: "IN",
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    configureCaptcha();
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(value, appVerifier)
      .then(async (confirmationResult) => {
        window.confirmationResult = confirmationResult;
        // console.log(confirmationResult);
        phoneOTPMessage();
      })
      .catch((e) => {
        console.log(e);
        configureCaptcha();
        Swal.fire(
          "error",
          "Too many login attempts.",
          "Please try again later."
        );
      });
  };

  const onSubmitOTP = (e) => {
    if (otp1 === "") {
      return setOtpVal(true);
    } else if (otp2 === "") {
      return setOtpVal(true);
    } else if (otp3 === "") {
      return setOtpVal(true);
    } else if (otp5 === "") {
      return setOtpVal(true);
    } else if (otp6 === "") {
      return setOtpVal(true);
    } else {
      setOtp(false);
      e.preventDefault();
      const code = fullOtp;

      window.confirmationResult
        .confirm(code)
        .then(async (result) => {
          // User signed in successfully.
          //console.log(result);
          const user = result.user;
          //  console.log(user);
          const idTokenResult = await firebase
            .auth()
            .currentUser.getIdTokenResult();
          //  console.log(idTokenResult);
          await confirmContinueWithFirebaseLgoinPhoneNumber(
            idTokenResult.token,
            idTokenResult.claims.phone_number
          );

          handleNotification(user);
        })
        .catch((e) => {
          setPhoneLogin(true);
          return Swal.fire("error", "Invaild Code", "error");
        });
    }
  };

  const confirmContinueWithFirebaseLgoinPhoneNumber = async (ftoken) => {
    let googledata = {
      identifier: value,
      firebaseToken: ftoken,
    };
    // console.log(googledata);
    url
      .put("/v1/auth/continue-with-firebase", googledata)
      .then(async (res) => {
        // console.log(res);
        const resp = res?.data;
        if (resp.Message === "Success") {
          if (resp?.data?.isSignedUp && !resp?.data?.userInfo) {
            LoginExsistingNumber(
              googledata.identifier,
              googledata.firebaseToken
            );
            // console.log("-----------isSignedUp === true----------- ");
            setPtoken(resp?.data?.token);
          } else if (!resp?.data?.isSignedUp) {
            // console.log("-----------isSignedUp === false----------- ");
            //  console.log(resp?.data?.token + " is not signed up");
            setPtoken(resp?.data?.token);
            OTPsuccessMessage();
          }
        }
      })

      .catch((e) => console.log("get", e));
  };

  const LoginExsistingNumber = (iden, t) => {
    let ELBody = {
      identifier: iden,
      firebaseToken: t,
    };
    // return console.log(ELBody);
    url
      .post("/v1/auth/login", ELBody)
      .then((res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          localStorage.setItem(
            "Etoken",
            JSON.stringify(res?.data?.data?.token)
          );
          localStorage.setItem(
            "user",
            JSON.stringify([res?.data?.data?.userInfo])
          );

          lsDisplay(false);
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Account successfully Login",
          });
        } else {
        }
      })
      .catch((e) => {
        console.log(e.response.status);

        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: e.response.data.data.message
            ? e.response.data.data.message
            : e.response.data.message,
        });
      });
  };

  const CreateAccountWithPhone = () => {
    const isUserOver18FromNow = isOver18FromNow(Pdate);
    if (!isUserOver18FromNow) {
      return Swal.fire(
        "Missing",
        "Age should be 18 years or greater.",
        "warning"
      );
    }
    const dobDate = moment(PPMLED, "YYYY-MM-DD");
    const now = moment();
    if (!dobDate.isAfter(now)) {
      return Swal.fire(
        "Missing",
        "Enter valid medical license date  ",
        "warning"
      );
    }

    let Pbody = {
      token: Ptoken,
      name: Pname,
      email: Pemail,
      password: PPassword,
      medicalLicense: PEMLN,
      mlExpiry: PPMLED,
      dob: Pdate,
      gender: Pgender,
      profession: PProfession,
      mobileNo: value,
      drivingLicense: PDLN,
      countryCode: PcountryCode,
    };

    console.log(Pbody);

    if (!Pbody?.name)
      return Swal.fire("Missing", "User Name must Required", "warning");
    else if (!Pbody?.email)
      return Swal.fire("Missing", "Email must Required", "warning");
    else if (!Pbody?.mobileNo)
      return Swal.fire("Missing", "Mobile Number must Required", "warning");
    else if (!Pbody?.profession)
      return Swal.fire("Missing", "profession must Required", "warning");
    else if (!Pbody?.gender)
      return Swal.fire("Missing", "Gender must Required", "warning");
    else if (!Pbody?.dob)
      return Swal.fire("Missing", "Date of birth must Required", "warning");
    else if (!Pbody?.password)
      return Swal.fire("Missing", "password must Required", "warning");
    else if (!Pbody?.medicalLicense)
      return Swal.fire("Missing", "Medical License must Required", "warning");
    else if (!Pbody?.mlExpiry)
      return Swal.fire(
        "Missing",
        "Medical License Expire Date must Required",
        "warning"
      );
    else if (!Pbody?.drivingLicense)
      return Swal.fire("Missing", "Driving License must Required", "warning");
    else {
      setPBtnLoad(true);
      url
        .post(`/v1/auth/sign-up-presigned`, Pbody)
        .then(async (res) => {
          console.log(res);
          if (res.data.Message === "Success") {
            localStorage.setItem(
              "Etoken",
              JSON.stringify(res?.data?.data?.token)
            );
            localStorage.setItem(
              "user",
              JSON.stringify([res?.data?.data?.userInfo])
            );
            setUserData([res?.data?.data?.userInfo]);
            // handleNotification([EAccBody])
            SuccessMessageAccCreated();
            lsDisplay(false);
            history.push("/");
          } else {
            setPBtnLoad(false);
          }
        })
        .catch((e) => {
          console.log(e.response);
          setPBtnLoad(false);

          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "warning",
            title: e.response.data.data.message,
          });
        });
    }
  };

  const loginUser = () => {
    let isNull = true;
    let isNum = /^\d+$/.test(value);

    if (isNum) {
      isNull = false;
    } else {
      isNull = true;
    }

    const sUrl = isNull ? `email=${value}` : `value=${value}`;

    if (value === "")
      return Swal.fire("Missing", "User Name or Number", "warning");
    else if (password === "")
      return Swal.fire("Missing", "Password", "warning");
    else
      url
        .get(`/user/login/?${sUrl}&password=${password}`)
        .then((res) => {
          if (res.data.Message === "Success") {
            // localStorage.setItem("user", JSON.stringify(res.data.data));
            handleNotification(res.data.data);
            lsDisplay(false);
            history.push("/");
          } else {
          }
        })
        .catch((e) => console.log(e));
  };

  const fullOtp = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;

  const submitOtp = (event) => {
    if (event.key === "Enter") {
      onSubmitOTP(event);
    }
  };

  const resetPassword = () => {
    setEmailS(false);
    setEmail(false);
    setReset(true);
    setPwd(false);
    setShowLoginNsignupbar(false);
    setSignup(false);
    setSignupbtn(false);
    setOtp(false);
    setAfterPhoneOtp(false);
    setResetOTP(false);
    setNewPassword(false);
    setLoginbtn(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const resetPasswordOTP = () => {
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(true);
    setShowLoginNsignupbar(false);
    setSignup(false);
    setSignupbtn(false);
    setOtp(false);
    setAfterPhoneOtp(false);
    setNewPassword(false);
    setLoginbtn(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const passwordNew = () => {
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setShowLoginNsignupbar(false);
    setNewPassword(true);
    setSignup(false);
    setSignupbtn(false);
    setOtp(false);
    setAfterPhoneOtp(false);
    setLoginbtn(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const ConfirmPass = () => {
    setEmailS(false);
    setEmail(true);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setShowLoginNsignupbar(true);
    setNewPassword(false);
    setSignup(false);
    setAfterFBorG(false);
    setLoginbtn(true);
    setSignupbtn(false);
    setOtp(false);
    setAfterPhoneOtp(false);
    setPhoneLogin(false);
  };

  const signupSection = () => {
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setShowLoginNsignupbar(true);
    setNewPassword(false);
    setSignup(true);
    setLoginbtn(false);
    setSignupbtn(true);
    setOtp(false);
    setAfterPhoneOtp(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const loginSection = () => {
    setEmailS(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setShowLoginNsignupbar(true);
    setNewPassword(false);
    setSignup(false);
    setSignupbtn(false);
    setEmail(true);
    setLoginbtn(true);
    setOtp(false);
    setAfterPhoneOtp(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const continuePhone = () => {
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setShowLoginNsignupbar(false);
    setNewPassword(false);
    setSignup(false);
    setLoginbtn(false);
    setSignupbtn(false);
    setPhoneLogin(true);
    setAfterFBorG(false);
  };

  const phoneOTPMessage = () => {
    Swal.fire({
      title: "OTP Sent To Phone ",
      text: "OTP Sent Successfully.",
      imageUrl: mobileOtpSend,
      // imageWidth: 500,
      // imageHeight: 328,
      imageAlt: "Custom image",
      confirmButtonText: "NEXT",
      padding: "25px",
      confirmButtonColor: "#08754C",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setOtp(true);
        setPhoneLogin(false);
      }
    });
  };

  const OTPsuccessMessage = () => {
    Swal.fire({
      title: "Congratulations ",
      text: "You have successfully Signed Up.",
      imageUrl: successImage,
      imageWidth: 285,
      // imageHeight: 328,
      imageAlt: "Custom image",
      confirmButtonText: "Next",
      padding: "20px",
      confirmButtonColor: "#08754C",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        AfterPhoneOtpSection();
      }
    });
  };

  const SuccessMessageAccCreated = () => {
    Swal.fire({
      title: "Congratulations ",
      text: "Your Account have successfully Created.",
      imageUrl: successImage,
      imageWidth: 285,
      // imageHeight: 328,
      imageAlt: "Custom image",
      confirmButtonText: "Next",
      padding: "20px",
      confirmButtonColor: "#08754C",
    }).then((result) => {
      if (result.isConfirmed) {
        lsDisplay(false);
        history.push("/");
      }
    });
  };

  // const SuccessMessageGoogleAccCreated = () => {
  //   Swal.fire({
  //     title: "Congratulations ",
  //     text: "You have successfully Signed Up using Google Account.",
  //     imageUrl: successImage,
  //     imageWidth: 285,
  //     // imageHeight: 328,
  //     imageAlt: "Custom image",
  //     confirmButtonText: "Next",
  //     padding: "20px",
  //     confirmButtonColor: "#08754C",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       AfterFBorGSection();
  //     }
  //   });
  // };

  // const SuccessMessageFBAccCreated = () => {
  //   Swal.fire({
  //     title: "Congratulations ",
  //     text: "You have successfully Signed Up using Facebook Account.",
  //     imageUrl: successImage,
  //     // imageWidth: 500,
  //     // imageHeight: 328,
  //     imageAlt: "Custom image",
  //     confirmButtonText: "Next",
  //     padding: "50px",
  //     confirmButtonColor: "#08754C",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       AfterFBorGSection();
  //     }
  //   });
  // };

  const AfterPhoneOtpSection = () => {
    setShowLoginNsignupbar(true);
    setSignupbtn(true);
    setOtp(false);
    setAfterPhoneOtp(true);
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setNewPassword(false);
    setSignup(false);
    setLoginbtn(false);
    setPhoneLogin(false);
    setAfterFBorG(false);
  };

  const AfterFBorGSection = () => {
    setShowLoginNsignupbar(true);
    setSignupbtn(true);
    setOtp(false);
    setAfterPhoneOtp(false);
    setEmailS(false);
    setEmail(false);
    setReset(false);
    setPwd(false);
    setResetOTP(false);
    setNewPassword(false);
    setSignup(false);
    setLoginbtn(false);
    setPhoneLogin(false);
    setAfterFBorG(true);
  };

  const sendOtp = (e) => {
    if (!value) return setnumberVal(false);
    // else if (!countryCode.value ) return  Swal.fire('Missing','Country Code is Required','warning' )
    else setnumberVal(true);
    onSignInSubmit(e);
  };

  const pwdUse = () => {
    setOtp(false);
    setPwd(true);
  };

  const getHelp = () => {
    lsDisplay(false);
    helpDis(true);
  };

  // const createAccountFB = (profile) => {
  //   let body = {
  //     name: profile.name,
  //     email: profile.email,
  //     thirdPartyKey: profile.userId,
  //     consumerId: "1",
  //   };

  //   url
  //     .post(`/user/`, body)
  //     .then(async (res) => {
  //       if (res.data.Message === "Failure") {
  //       } else {
  //         handleNotification();
  //         localStorage.setItem("user", JSON.stringify(res.data.data));
  //         loginUser();
  //         //lsDisplay(false);
  //         SuccessMessageGoogleAccCreated();
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const handleChangeOtp1 = (e) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 1) {
      setOtp1(value);
    }
  };

  const handleChangeOtp2 = (e) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 1) {
      setOtp2(value);
    }
  };

  const handleChangeOtp3 = (e) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 1) {
      setOtp3(value);
    }
  };

  const handleChangeOtp4 = (e) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 1) {
      setOtp4(value);
    }
  };

  const handleChangeOtp5 = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 1) {
      setOtp5(value);
    }
  };

  const handleChangeOtp6 = (e) => {
    const value = e.target.value.replace(/\D/g, "");

    if (value.length <= 1) {
      setOtp6(value);
    }
  };

  const handleChangeEmail = (e) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (regex.test(e.target.value)) {
      setSemail(e.target.value);

      // setCheckValMail(true);

      setEmailVal(false);
      e.target.value === "" ? setMissEmailVal(true) : setMissEmailVal(false);
    } else {
      // setCheckValMail(false);
      emailF === "" ? setMissEmailVal(true) : setMissEmailVal(false);
    }
  };
  // Notification Token

  const handleNotification = (data) => {
    const messaging = firebase.messaging();
    messaging
      .getToken({
        vapidKey:
          "BD6VIVynX7yEy9fasP_qc5ON_Ut0oIIibX44bX5ryBxlGIygx7uLenG5VS1zIMOoKuQL5qV4bBY0Fx-pe3VcBBI",
      })
      .then((currentToken) => {
        //console.log("token: ", currentToken);
        const body = {
          clientId: "df48aa8c-4345-466a-8b8e-e959a2bfcdaa",
          userId: data[0]?.userID,
          device: "OTHERS",
          token: currentToken,
        };
        url
          .patch(`/v1/push-notifications/upsert-token`, body)
          .then(async (res) => {
            if (res.data.Message === "Success") {
              // Swal.fire('Successfully', 'Token Generated', 'success')
            } else {
              //  alert(res.data.data);
            }
          });

        if (currentToken) {
        } else {
        }
      })
      .catch((err) => {});
  };

  const ErrorMessageModal = (title, text) => {
    Swal.fire({
      title: title,
      text: text,
      imageUrl: warningModal,
      imageWidth: 85,
      // imageHeight: 328,
      imageAlt: "Custom image",
      // confirmButtonText: btnTxt,
      padding: "20px",
      cancelButtonColor: "#FF7474",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "No, cancel!",
    });
  };

  const handlerEName = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setEName(Ename);
    } else {
      setEName(inputValue);
    }
  };

  const handlerEDLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setEDLN(EDLN);
    } else {
      setEDLN(inputValue);
    }
  };

  const handlerEMLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setEMLN(EEMLN);
    } else {
      setEMLN(inputValue);
    }
  };

  const handlerGName = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setGName(Gname);
    } else {
      setGName(inputValue);
    }
  };

  const handlerGDLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setGDLN(GDLN);
    } else {
      setGDLN(inputValue);
    }
  };

  const handlerGMLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setGMLN(GEMLN);
    } else {
      setGMLN(inputValue);
    }
  };

  const handlerPName = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setPName(Pname);
    } else {
      setPName(inputValue);
    }
  };

  const handlerPDLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setPDLN(PDLN);
    } else {
      setPDLN(inputValue);
    }
  };

  const handlerPMLN = (event) => {
    const inputValue = event;
    if (inputValue.startsWith(" ")) {
      setPMLN(PEMLN);
    } else {
      setPMLN(inputValue);
    }
  };

  return (
    <>
      <div className="modals">
        <div className="overlay"></div>
        <div className="lsmodal">
          {/* ***************************** Left Model Side ****************************** */}
          <div
            className="left-lsmodal"
            style={{ display: "block", width: "384px" }}>
            <div className="modalpic d-none d-lg-flex">
              <img className="leaf" src={clip} alt="logo" />

              <img className="leftstain" src={strain} alt="logo" />

              <div className="Ls-leftlogo">
                {!isLoaded ? (
                  <Spinner
                    style={{ width: "80px", height: "80px" }}
                    animation="grow"
                    variant="light"
                    className="textColor"
                  />
                ) : (
                  ""
                )}

                <img
                  style={{
                    width: "250px",
                    maxHeight: "300px",
                    marginTop: "77px",
                    marginLeft: "31px",
                    display: !isLoaded ? "none" : "flex",
                  }}
                  src={headLogo ? headLogo : logo}
                  onLoad={() => {
                    setIsLoaded(true);
                  }}
                  alt="logo"
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: "25px",
                padding: "16px",
                marginTop: "160px",
              }}>
              <span className="login">Login to </span>
              <br />
              <div className="tick">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="9.53115"
                    cy="9.65578"
                    r="9.53115"
                    fill="#FFC541"
                  />
                  <path
                    d="M6.69481 12.337L4.01361 9.65577L3.10059 10.5624L6.69481 14.1566L14.4105 6.4409L13.5039 5.5343L6.69481 12.337Z"
                    fill="#FAFBFF"
                  />
                </svg>

                <span className="logintxt ">Manage your orders </span>
                <br />
              </div>
              <div className="tick">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="9.53115"
                    cy="9.65578"
                    r="9.53115"
                    fill="#FFC541"
                  />
                  <path
                    d="M6.69481 12.337L4.01361 9.65577L3.10059 10.5624L6.69481 14.1566L14.4105 6.4409L13.5039 5.5343L6.69481 12.337Z"
                    fill="#FAFBFF"
                  />
                </svg>

                <span className="logintxt ">
                  Get exclusive deals and offers
                </span>
                <br />
              </div>
              <div className="tick">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="9.53115"
                    cy="9.65578"
                    r="9.53115"
                    fill="#FFC541"
                  />
                  <path
                    d="M6.69481 12.337L4.01361 9.65577L3.10059 10.5624L6.69481 14.1566L14.4105 6.4409L13.5039 5.5343L6.69481 12.337Z"
                    fill="#FAFBFF"
                  />
                </svg>

                <span className="logintxt ">
                  Get personalized recommendations
                </span>
              </div>
            </div>
            <div className="modalpic d-none d-lg-flex"></div>
          </div>

          {/* ***************************** Right Model Side ****************************** */}

          <div className="modal-form">
            <i
              className="fas fa-times"
              onClick={() => {
                lsDisplay(false);
                setProfile(false);
                setLoginModal(false);
              }}
              style={{ cursor: "pointer", padding: "0px", background: "none" }}
            />
            <div
              style={{
                height: "125px",
                width: "125px",
                margin: "30px 0px",
                display: "flex",
                alignItems: "center",
              }}>
              {!isLoaded ? (
                <Spinner
                  style={{
                    width: "80px",
                    height: "80px",
                    marginBottom: "30px",
                  }}
                  animation="grow"
                  variant="primary"
                  className="colorBackground"
                />
              ) : (
                ""
              )}
              <img
                className="LS-logo"
                style={{
                  marginBottom: "30px",
                  display: !isLoaded ? "none" : "flex",
                }}
                src={headLogo ? headLogo : logo}
                onLoad={() => {
                  setIsLoaded(true);
                }}
                alt="logo"
              />
            </div>

            {showLoginNsignupbar ? (
              <>
                <div
                  className="row loginNsignupRow"
                  style={{ width: "399px", height: "50px" }}>
                  <div className="col " style={{ padding: "0px" }}>
                    <span
                      className={loginbtn ? "Ls-Loginbtn MLeft " : "MLeft "}
                      style={{ cursor: "pointer", fontWeight: "500" }}
                      onClick={() => {
                        loginSection();
                      }}>
                      Login
                    </span>
                  </div>

                  <div className="col" style={{ padding: "0px" }}>
                    <span
                      className={signupbtn ? "Ls-Loginbtn" : "Ls-Signupbtn"}
                      style={{ cursor: "pointer", fontWeight: "500" }}
                      onClick={() => {
                        signupSection();
                      }}>
                      Sign up
                    </span>
                  </div>
                </div>
              </>
            ) : null}

            {pwd && (
              <>
                <p className="textColor" style={{ marginRight: "37%" }}>
                  {" "}
                  <mark
                    style={{ cursor: "pointer", fontWeight: "500" }}
                    onClick={() => pwdUse()}>
                    Login
                  </mark>{" "}
                  <mark>or</mark>{" "}
                  <mark
                    className="textColor"
                    style={{ cursor: "pointer", fontWeight: "500" }}
                    onClick={() => {
                      lsDisplay(false);
                    }}>
                    Sign up
                  </mark>{" "}
                </p>
                <div className="input-login">
                  <div className="input-label" style={{ paddingBottom: "0px" }}>
                    <p>
                      <span
                        className="MLeft"
                        style={{ cursor: "pointer", marginLeft: "-11px" }}
                        eye-icon
                        onClick={() => {
                          setEmail(true);
                          setPwd(false);
                        }}>
                        Email
                      </span>{" "}
                      or{" "}
                      <span
                        onClick={() => {
                          setPwd(true);
                        }}>
                        Mobile Number
                      </span>
                    </p>

                    <div
                      style={{
                        marginBottom: "16px",
                        marginLeft: "-60px",
                        width: "152%",
                      }}>
                      <PhoneInput
                        placeholder="XXXXXXXXXX"
                        defaultCountry="US"
                        value={value}
                        onChange={setValue}
                        error={
                          value
                            ? isValidPhoneNumber(value)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                    </div>
                  </div>
                  {showpass ? (
                    <img
                      className="eye-icon"
                      src={eye}
                      style={{
                        position: "absolute",
                        marginLeft: "26%",
                        marginBottom: "-23px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPass(!showpass);
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={eyeHidden}
                      className="eye-icon"
                      style={{
                        position: "absolute",
                        marginLeft: "26%",
                        marginBottom: "-23px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPass(!showpass);
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div className="input-login2">
                  <input
                    type={showpass ? "text" : "password"}
                    className="pass-input2"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="textColorDis" onClick={() => loginUser()}>
                  LOGIN
                </button>
                <div className="lower-modal">
                  <p>
                    Forgot your password?{" "}
                    <mark
                      style={{ cursor: "pointer" }}
                      onClick={() => resetPassword()}>
                      Reset here
                    </mark>
                  </p>
                  <p>
                    Having trouble logging in?{" "}
                    <mark
                      style={{ cursor: "pointer" }}
                      onClick={() => getHelp()}>
                      Get help
                    </mark>
                  </p>
                </div>
              </>
            )}
            {email && (
              <>
                <div className="input-login2">
                  <span className="Ls-label">Email</span>
                  <span>
                    <input
                      type="email"
                      placeholder="Enter Email or Phone"
                      onChange={(e) => {
                        handleChangeEmail(e);
                        setEmailF(e);
                      }}
                    />
                  </span>
                  {emailVal ? (
                    <mark
                      className="dangerColor"
                      style={{ marginRight: "28%", marginTop: " -2%" }}>
                      Email is not in the correct format
                    </mark>
                  ) : null}
                  {missEmailVal ? (
                    <mark
                      className="dangerColor"
                      style={{ marginRight: "46%", marginTop: " -2%" }}>
                      Email is required
                    </mark>
                  ) : null}
                  <span className="Ls-label">Password</span>
                  <span>
                    <input
                      type={showpass ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) => setSPassword(e.target.value)}
                    />

                    <div
                      className="Ep-changepass"
                      onClick={() => {
                        resetPassword();
                      }}>
                      Forgot Password?
                    </div>
                  </span>
                  {showpass ? (
                    <img
                      className="login-eye-email"
                      src={eye}
                      onClick={() => {
                        setShowPass(!showpass);
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      className="login-eye-email"
                      src={eyeHidden}
                      onClick={() => {
                        setShowPass(!showpass);
                      }}
                      alt=""
                    />
                  )}
                </div>
                <button
                  className="LoginBtn"
                  disabled={SPassword.length < 8}
                  style={{ background: SPassword.length < 8 ? "gray" : "" }}
                  onClick={() => {
                    signInWithEmailAndPasswordFirebase();
                  }}>
                  LOGIN
                </button>
                <hr style={{ marginTop: "40px" }} />
                <div className="or">
                  <p>OR</p>
                </div>
                <div className="signup-btn-ls">
                  <button
                    onClick={() => {
                      googlefirbaseLogin();
                    }}>
                    <img
                      style={{ marginRight: "48px" }}
                      src={googleIcon}
                      alt="goog"
                    />
                    CONTINUE WITH GOOGLE
                  </button>
                  <button onClick={() => continuePhone()}>
                    <svg
                      style={{ marginRight: "48px", marginLeft: "-15px" }}
                      width="17"
                      height="31"
                      viewBox="0 0 17 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.255 0H1.74497C0.782942 0 0 0.800869 0 1.78493V29.2151C0 30.1991 0.782942 31 1.74497 31H15.255C16.2171 31 17 30.1991 17 29.2151V1.78493C17 0.800869 16.2171 0 15.255 0ZM12.3697 0.968655L12.0634 1.9322L4.93629 1.93247L4.63003 0.968924L12.3697 0.968655ZM16.0527 29.2146C16.0527 29.6644 15.695 30.0308 15.2548 30.0308L1.74538 30.0305C1.30565 30.0305 0.947446 29.6646 0.947446 29.2143V1.78487C0.947446 1.33506 1.30515 0.968661 1.74538 0.968661H3.63487L4.14213 2.5666C4.20544 2.76627 4.38719 2.90119 4.59242 2.90119H12.4077C12.6129 2.90119 12.7947 2.76628 12.858 2.5666L13.3652 0.968661H15.2547C15.6944 0.968661 16.0526 1.33455 16.0526 1.78487L16.0527 29.2146ZM8.50014 25.1674C7.59267 25.1674 6.8549 25.9227 6.8549 26.8503C6.8549 27.7786 7.59328 28.5338 8.50014 28.5338C9.4076 28.5338 10.1459 27.7785 10.1459 26.8503C10.1459 25.9223 9.40754 25.1674 8.50014 25.1674ZM8.50014 27.5648C8.11501 27.5648 7.8016 27.2443 7.8016 26.8503C7.8016 26.4563 8.11498 26.1358 8.50014 26.1358C8.88529 26.1358 9.19867 26.4563 9.19867 26.8503C9.19893 27.2445 8.88529 27.5648 8.50014 27.5648ZM10.2826 10.6757L5.78367 16.6981C5.69029 16.8227 5.54943 16.8883 5.40725 16.8883C5.30701 16.8883 5.20598 16.8559 5.11998 16.7887C4.91212 16.6263 4.87255 16.3222 5.03135 16.1098L9.53026 10.0875C9.68907 9.87487 9.98609 9.83439 10.194 9.99683C10.4016 10.1593 10.4414 10.4631 10.2826 10.6757ZM11.9695 13.2148L7.47057 19.2372C7.37719 19.3618 7.23632 19.4274 7.09415 19.4274C6.9939 19.4274 6.89287 19.395 6.80688 19.3278C6.59902 19.1654 6.55944 18.8613 6.71825 18.6489L11.2169 12.6266C11.3757 12.414 11.673 12.3735 11.8806 12.5359C12.0884 12.6981 12.128 13.0022 11.9695 13.2148Z"
                        fill="#585858"
                      />
                    </svg>
                    CONTINUE USING PHONE
                  </button>
                </div>
              </>
            )}

            {reset && (
              <>
                <div
                  className="backBtn-lsModal signup-phone-backBtn"
                  onClick={() => {
                    loginSection();
                  }}>
                  {" "}
                  &lt; &nbsp;<span>Back</span>{" "}
                </div>
                <div className="reset-mainDiv">
                  <div className="reset-uper-div">
                    <div className="reset-img">
                      <svg
                        width="165"
                        height="165"
                        viewBox="0 0 165 165"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="82.5"
                          cy="82.5"
                          r="82.5"
                          fill="#08754C"
                          fill-opacity="0.1"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M75.8897 50.9773C83.2287 50.9773 89.2457 56.9129 89.2457 64.1524V74.1521H96.5077V64.1524C96.5078 52.9559 87.2355 43.8136 75.8897 43.8136C64.5438 43.8136 55.2715 52.9559 55.2715 64.1524V74.1521H62.5335V64.1524C62.5336 56.9129 68.5507 50.9773 75.8897 50.9773Z"
                          fill="#DEDBEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M96.5077 64.1524C96.5077 52.9559 87.2354 43.8136 75.8896 43.8136C73.567 43.8136 71.3303 44.1976 69.2432 44.9033C70.0762 44.8005 70.9229 44.747 71.7831 44.747C83.129 44.747 92.4013 53.8937 92.4013 65.0858V74.1521H96.5077V64.1524Z"
                          fill="#D2CFE9"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M54.3977 72.5802C50.1146 72.5802 46.6104 76.0369 46.6104 80.2621V91.6909C46.6104 107.577 59.7853 120.574 75.8896 120.574C81.3678 120.574 86.5065 119.068 90.8982 116.46L105.169 89.172V80.2621C105.169 76.0371 101.664 72.5802 97.3814 72.5802C83.0521 72.5802 68.7271 72.5802 54.3977 72.5802Z"
                          fill="#FDB441"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M75.8893 83.8169C72.3895 83.8169 69.5508 86.6126 69.5508 90.0651C69.5508 92.2937 70.7324 94.2454 72.5072 95.3574L71.1768 103.909C71.1335 104.186 71.3509 104.437 71.6353 104.437H80.1429C80.4273 104.437 80.6447 104.186 80.6014 103.909L79.2711 95.3574C81.0459 94.2454 82.2275 92.2936 82.2275 90.0651C82.2278 86.6126 79.3891 83.8169 75.8893 83.8169Z"
                          fill="#025F80"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M103.512 89.0916C94.2665 89.0916 86.7734 96.483 86.7734 105.603C86.7734 109.761 88.331 113.557 90.898 116.46C93.9676 119.926 98.4815 122.119 103.512 122.119C112.757 122.119 120.254 114.723 120.254 105.603C120.254 97.0368 113.639 89.9937 105.169 89.172C104.625 89.1183 104.073 89.0916 103.512 89.0916Z"
                          fill="#08754C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M74.8486 83.8973C77.2301 84.8128 78.9234 87.1264 78.9234 89.8329C78.9234 92.0972 77.7418 94.0802 75.967 95.2101L77.3796 104.437H80.1439C80.4283 104.437 80.6457 104.186 80.6024 103.909L79.2721 95.3574C81.0469 94.2454 82.2285 92.2936 82.2285 90.0651C82.2285 86.6128 79.3898 83.8169 75.89 83.8169C75.5369 83.8169 75.1882 83.8437 74.8486 83.8973Z"
                          fill="#024C67"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M120.255 105.603C120.255 97.0368 113.64 89.9937 105.169 89.172C104.626 89.1185 104.074 89.0916 103.512 89.0916C102.331 89.0916 101.171 89.2121 100.058 89.4444C108.506 90.284 115.102 97.3182 115.102 105.875C115.102 113.825 109.402 120.462 101.819 122.034C102.376 122.087 102.942 122.119 103.512 122.119C112.757 122.119 120.255 114.723 120.255 105.603Z"
                          fill="#1C8E63"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M105.168 80.2618C105.168 76.0368 101.664 72.58 97.3811 72.58H96.5073H92.4008H89.2451C93.5282 72.58 97.0324 76.0367 97.0324 80.2618V90.3733C97.9968 89.9758 99.0109 89.6587 100.057 89.4443C101.171 89.2121 102.33 89.0915 103.511 89.0915C104.073 89.0915 104.625 89.1182 105.168 89.1719V80.2618Z"
                          fill="#FE9901"
                        />
                        <path
                          d="M103.512 112.077C102.852 112.077 102.317 111.548 102.317 110.895V105.581C102.317 104.928 102.852 104.399 103.512 104.399C104.612 104.399 105.634 103.965 106.39 103.177C107.141 102.395 107.523 101.37 107.466 100.292C107.359 98.2613 105.659 96.6384 103.596 96.5972C103.569 96.5966 103.541 96.5963 103.514 96.5963C101.542 96.5963 99.8641 98.0252 99.5927 99.9512C99.5675 100.136 99.5545 100.321 99.5545 100.498C99.5545 101.151 99.0196 101.68 98.3598 101.68C97.6999 101.68 97.165 101.151 97.165 100.498C97.165 100.216 97.1855 99.9236 97.2255 99.6297C97.6685 96.4865 100.427 94.1719 103.645 94.2341C106.953 94.3002 109.68 96.9068 109.853 100.168C109.944 101.901 109.33 103.547 108.123 104.804C107.206 105.76 106.002 106.402 104.707 106.649V110.895C104.706 111.548 104.172 112.077 103.512 112.077Z"
                          fill="#F4EAFA"
                        />
                        <path
                          d="M103.513 116.976C103.448 116.976 103.381 116.971 103.315 116.96C102.664 116.853 102.224 116.244 102.333 115.6L102.337 115.574C102.446 114.93 103.06 114.494 103.712 114.602C104.363 114.71 104.803 115.319 104.694 115.962L104.69 115.989C104.592 116.567 104.086 116.976 103.513 116.976Z"
                          fill="#F4EAFA"
                        />
                      </svg>
                    </div>

                    <div className="reset-text">
                      Please enter your Email Address to receive a verification
                      code
                    </div>
                  </div>

                  <div className="reset-lower-div">
                    <span className="Ls-label">Email</span>
                    <span className="reset-emailField">
                      <input
                        type="email"
                        placeholder="Enter Email "
                        onChange={(e) => {
                          setRemail(e.target.value);
                        }}
                      />
                    </span>

                    <button
                      className="LoginBtn"
                      disabled={Remail?.length === 0}
                      style={{ background: Remail?.length === 0 ? "gray" : "" }}
                      onClick={() => {
                        resetPass();
                      }}>
                      Send code
                    </button>
                  </div>
                </div>
              </>
            )}

            {resetOTP && (
              <>
                <div className="reset-mainDiv">
                  <div className="reset-uper-div">
                    <div className="reset-img">
                      <svg
                        width="165"
                        height="165"
                        viewBox="0 0 165 165"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="82.5"
                          cy="82.5"
                          r="82.5"
                          fill="#08754C"
                          fill-opacity="0.1"
                        />
                        <path
                          d="M80.076 46.6182L44.7461 75.166V80.4861H82.0061H119.266V75.166L83.9362 46.6182C82.8104 45.7085 81.2019 45.7085 80.076 46.6182Z"
                          fill="#FF9102"
                        />
                        <path
                          d="M113.124 109.992H50.8873C49.1914 109.992 47.8164 108.617 47.8164 106.921V48.7489C47.8164 47.053 49.1912 45.678 50.8873 45.678H113.124C114.82 45.678 116.195 47.053 116.195 48.7489V106.921C116.195 108.617 114.82 109.992 113.124 109.992Z"
                          fill="url(#paint0_linear_564_4087)"
                        />
                        <path
                          d="M116.195 105.21V106.406C116.195 108.385 114.588 109.992 112.609 109.992H51.4031C49.4235 109.992 47.8169 108.385 47.8169 106.406V105.21H116.195Z"
                          fill="url(#paint1_linear_564_4087)"
                        />
                        <path
                          d="M101.762 55.402H62.2495V57.6541H101.762V55.402Z"
                          fill="#C2CECE"
                        />
                        <path
                          d="M101.762 64.4099H62.2495V66.662H101.762V64.4099Z"
                          fill="#C2CECE"
                        />
                        <path
                          d="M101.762 73.6226H62.2495V75.8746H101.762V73.6226Z"
                          fill="#C2CECE"
                        />
                        <path
                          d="M101.762 82.8352H62.2495V85.0873H101.762V82.8352Z"
                          fill="#C2CECE"
                        />
                        <path
                          d="M106.676 76.0798C103.612 76.0798 100.805 74.9848 98.6225 73.1663L97.77 73.316L116.195 91.7413V71.724C113.887 74.3904 110.479 76.0798 106.676 76.0798Z"
                          fill="url(#paint2_linear_564_4087)"
                        />
                        <path
                          d="M119.266 75.2339V117.229C119.266 118.924 117.89 120.3 116.195 120.3H47.817C46.1218 120.3 44.7461 118.924 44.7461 117.229V75.2339L82.4157 104.946L119.266 75.2339Z"
                          fill="url(#paint3_linear_564_4087)"
                        />
                        <path
                          d="M118.852 118.765L84.3427 90.9411C83.2178 90.0341 81.6126 90.0341 80.4877 90.9411L45.4526 119.189C46.0161 119.868 46.8657 120.3 47.8167 120.3H116.195C117.331 120.3 118.321 119.682 118.852 118.765Z"
                          fill="url(#paint4_linear_564_4087)"
                        />
                        <path
                          d="M106.675 77.1031C113.629 77.1031 119.266 71.4661 119.266 64.5125C119.266 57.5589 113.629 51.9219 106.675 51.9219C99.7215 51.9219 94.0845 57.5589 94.0845 64.5125C94.0845 71.4661 99.7215 77.1031 106.675 77.1031Z"
                          fill="#08754C"
                        />
                        <path
                          d="M103.836 59.4697C103.836 59.0613 104.001 58.7392 104.331 58.5036L106.782 56.147C106.954 55.9743 107.166 55.8877 107.418 55.8877C107.701 55.8877 107.956 55.9625 108.184 56.1116C108.411 56.261 108.525 56.4614 108.525 56.7125V72.3128C108.525 72.5643 108.4 72.7646 108.148 72.9138C107.897 73.0632 107.607 73.1377 107.277 73.1377C106.931 73.1377 106.636 73.0632 106.393 72.9138C106.149 72.7647 106.028 72.5643 106.028 72.3128V59.2103L105.203 60.2473C105.046 60.4044 104.873 60.4829 104.684 60.4829C104.449 60.4829 104.248 60.377 104.083 60.1649C103.918 59.9527 103.836 59.7211 103.836 59.4697Z"
                          fill="white"
                        />
                        <path
                          d="M48.7738 107.878V49.7061C48.7738 48.0102 50.1488 46.6352 51.8447 46.6352H114.081C114.684 46.6352 115.244 46.8115 115.719 47.1114C115.174 46.2511 114.217 45.678 113.124 45.678H50.8873C49.1914 45.678 47.8164 47.053 47.8164 48.7489V106.921C47.8164 108.014 48.3895 108.972 49.25 109.516C48.95 109.041 48.7738 108.481 48.7738 107.878Z"
                          fill="url(#paint5_linear_564_4087)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_564_4087"
                            x1="54.7235"
                            y1="50.5497"
                            x2="126.334"
                            y2="122.161"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="#E8EFEE" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_564_4087"
                            x1="116.792"
                            y1="104.662"
                            x2="44.5479"
                            y2="110.478"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C2CECE" stop-opacity="0" />
                            <stop
                              offset="0.179"
                              stop-color="#AFBCBC"
                              stop-opacity="0.179"
                            />
                            <stop offset="1" stop-color="#5B6A6A" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_564_4087"
                            x1="116.94"
                            y1="82.4732"
                            x2="100.147"
                            y2="65.6811"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C2CECE" stop-opacity="0" />
                            <stop
                              offset="0.179"
                              stop-color="#AFBCBC"
                              stop-opacity="0.179"
                            />
                            <stop offset="1" stop-color="#5B6A6A" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_564_4087"
                            x1="82.0073"
                            y1="89.5654"
                            x2="82.0073"
                            y2="117.225"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFC200" />
                            <stop offset="0.268" stop-color="#FFBB00" />
                            <stop offset="0.659" stop-color="#FFA801" />
                            <stop offset="1" stop-color="#FF9102" />
                          </linearGradient>
                          <linearGradient
                            id="paint4_linear_564_4087"
                            x1="82.1533"
                            y1="110.678"
                            x2="82.1533"
                            y2="120.274"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFC200" />
                            <stop offset="0.268" stop-color="#FFBB00" />
                            <stop offset="0.659" stop-color="#FFA801" />
                            <stop offset="1" stop-color="#FF9102" />
                          </linearGradient>
                          <linearGradient
                            id="paint5_linear_564_4087"
                            x1="64.648"
                            y1="60.4752"
                            x2="12.2397"
                            y2="8.06843"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#C2CECE" stop-opacity="0" />
                            <stop
                              offset="0.179"
                              stop-color="#AFBCBC"
                              stop-opacity="0.179"
                            />
                            <stop offset="1" stop-color="#5B6A6A" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>

                    <div className="resetOTP-text">
                      Please enter the 12 Digits code sent to
                    </div>

                    <div className="resetOTP-email">{Remail}</div>
                  </div>

                  <span
                    className="reset-emailField"
                    style={{
                      width: "100%",
                      marginTop: "45px",
                    }}>
                    <input
                      type="email"
                      placeholder="Enter Code "
                      onChange={(e) => {
                        setRsendMail(e.target.value);
                      }}
                    />
                  </span>
                  <button
                    className="LoginBtn"
                    disabled={RsendMail.length < 6}
                    style={{ background: RsendMail.length < 6 ? "gray" : "" }}
                    onClick={() => {
                      confirmRcode();
                    }}>
                    Continue
                  </button>
                </div>
              </>
            )}

            {newPassword && (
              <>
                <div className="reset-mainDiv">
                  <div className="reset-uper-div">
                    <div className="reset-img">
                      <svg
                        width="165"
                        height="165"
                        viewBox="0 0 165 165"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle
                          cx="82.5"
                          cy="82.5"
                          r="82.5"
                          fill="#08754C"
                          fill-opacity="0.1"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M75.8897 50.9773C83.2287 50.9773 89.2457 56.9129 89.2457 64.1524V74.1521H96.5077V64.1524C96.5078 52.9559 87.2355 43.8136 75.8897 43.8136C64.5438 43.8136 55.2715 52.9559 55.2715 64.1524V74.1521H62.5335V64.1524C62.5336 56.9129 68.5507 50.9773 75.8897 50.9773Z"
                          fill="#DEDBEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M96.5077 64.1524C96.5077 52.9559 87.2354 43.8136 75.8896 43.8136C73.567 43.8136 71.3303 44.1976 69.2432 44.9033C70.0762 44.8005 70.9229 44.747 71.7831 44.747C83.129 44.747 92.4013 53.8937 92.4013 65.0858V74.1521H96.5077V64.1524Z"
                          fill="#D2CFE9"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M54.3977 72.5802C50.1146 72.5802 46.6104 76.0369 46.6104 80.2621V91.6909C46.6104 107.577 59.7853 120.574 75.8896 120.574C81.3678 120.574 86.5065 119.068 90.8982 116.46L105.169 89.172V80.2621C105.169 76.0371 101.664 72.5802 97.3815 72.5802C83.0521 72.5802 68.7271 72.5802 54.3977 72.5802Z"
                          fill="#FDB441"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M75.8898 83.8169C72.39 83.8169 69.5513 86.6126 69.5513 90.0651C69.5513 92.2937 70.7329 94.2454 72.5077 95.3574L71.1773 103.909C71.134 104.186 71.3514 104.437 71.6358 104.437H80.1434C80.4278 104.437 80.6452 104.186 80.6019 103.909L79.2716 95.3574C81.0464 94.2454 82.228 92.2936 82.228 90.0651C82.2283 86.6126 79.3896 83.8169 75.8898 83.8169Z"
                          fill="#025F80"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M103.512 89.0916C94.2665 89.0916 86.7734 96.483 86.7734 105.603C86.7734 109.761 88.331 113.557 90.898 116.46C93.9676 119.926 98.4815 122.119 103.512 122.119C112.757 122.119 120.254 114.723 120.254 105.603C120.254 97.0368 113.639 89.9937 105.169 89.172C104.625 89.1183 104.073 89.0916 103.512 89.0916Z"
                          fill="#08754C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M74.8481 83.8973C77.2296 84.8128 78.9229 87.1264 78.9229 89.8329C78.9229 92.0972 77.7413 94.0802 75.9665 95.2101L77.3791 104.437H80.1434C80.4278 104.437 80.6453 104.186 80.602 103.909L79.2716 95.3574C81.0464 94.2454 82.228 92.2936 82.228 90.0651C82.228 86.6128 79.3893 83.8169 75.8895 83.8169C75.5364 83.8169 75.1877 83.8437 74.8481 83.8973Z"
                          fill="#024C67"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M120.254 105.603C120.254 97.0368 113.64 89.9937 105.169 89.172C104.625 89.1185 104.073 89.0916 103.512 89.0916C102.33 89.0916 101.171 89.2121 100.057 89.4444C108.505 90.284 115.102 97.3182 115.102 105.875C115.102 113.825 109.402 120.462 101.818 122.034C102.375 122.087 102.941 122.119 103.512 122.119C112.757 122.119 120.254 114.723 120.254 105.603Z"
                          fill="#1C8E63"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M105.169 80.2618C105.169 76.0368 101.665 72.58 97.3816 72.58H96.5078H92.4013H89.2456C93.5286 72.58 97.0329 76.0367 97.0329 80.2618V90.3733C97.9972 89.9758 99.0114 89.6587 100.057 89.4443C101.171 89.2121 102.33 89.0915 103.512 89.0915C104.073 89.0915 104.626 89.1182 105.169 89.1719V80.2618Z"
                          fill="#FE9901"
                        />
                        <path
                          d="M95.9857 104.956C95.3275 104.382 94.2899 104.409 93.6682 105.018C93.0466 105.626 93.0762 106.585 93.7345 107.159L98.6526 111.451C99.3559 112.065 100.478 111.985 101.072 111.28L108.723 102.191C109.279 101.53 109.15 100.579 108.435 100.065C107.721 99.5514 106.691 99.6703 106.135 100.331L99.592 108.104L95.9857 104.956Z"
                          fill="white"
                        />
                      </svg>
                    </div>

                    <div className="newPassword-text">
                      Your new password must be different from previously used
                      password
                    </div>
                  </div>

                  <div className="reset-lower-div">
                    <span className="Ls-label">New Passsword</span>
                    <span className="newpasswordField">
                      <input
                        type={showpass ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => {
                          setRpassword(e.target.value);
                        }}
                      />
                    </span>

                    <span className="Ls-label">Confirm New Passsword</span>
                    <span className="newpasswordField">
                      <input
                        type={showpass ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => {
                          setRCpassword(e.target.value);
                        }}
                      />
                    </span>
                    <p className="pass-auth">
                      * Password should have 8 characters including 1 Special, 1
                      Numeric, and 1 Uppercase.
                    </p>

                    <button
                      disabled={RCpassword !== Rpassword}
                      style={{
                        marginTop: "40px",
                        background: RCpassword !== Rpassword ? "gray" : "",
                      }}
                      className="LoginBtn"
                      onClick={() => {
                        ConfirmPassword();
                      }}>
                      SAVE
                    </button>
                  </div>
                </div>
              </>
            )}

            {signup && (
              <>
                <div className="profile-Create">
                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col" style={{ display: "flex" }}>
                      <span className="Ep-label">
                        Full Name*
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter Name"
                          value={Ename}
                          onChange={(e) => handlerEName(e.target.value)}
                        />
                      </span>
                    </div>

                    <div className="col" style={{ display: "flex" }}>
                      <span className="Ep-label">
                        Email*
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name=""
                          id=""
                          value={Eemail}
                          placeholder="Enter Email"
                          onChange={(e) => setEemail(e.target.value.trim())}
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col-6 phoneW-100">
                      <div className="Ep-mobilenumber-lable">
                        <span className="Ep-label" style={{ width: "119px" }}>
                          Country
                        </span>
                        <span className="Ep-label">Mobile Number</span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <PhoneInput
                          className="Ep-numberNumber"
                          defaultCountry="US"
                          style={{ width: "100%" }}
                          placeholder="XXXXXXXXXXX"
                          onChange={setEphoneNumber}
                          error={
                            EphoneNumber
                              ? isValidPhoneNumber(EphoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                    </div>

                    <div className="col" style={{ display: "flex" }}>
                      <span className="Ep-label">
                        Gender*
                        <br />
                        <select
                          style={{ width: "100%", cursor: "pointer" }}
                          className="Ep-selectGender"
                          value={Egender}
                          placeholder="Gender"
                          onChange={(e) => setEGender(e.target.value)}>
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Rather Not Say">Other</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div className="col" style={{ display: "flex" }}>
                      <div className="Ep-label">
                        <span className="Ep-label">Date Of Birth*</span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          placeholder="MM/DD/YYYY"
                          max={moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setEDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Profession*
                          <br />
                          <select
                            style={{ cursor: "pointer" }}
                            className="Ep-selectGender"
                            value={EProfessional}
                            placeholder="Profession"
                            onChange={(e) => setEProfession(e.target.value)}>
                            <option value="" disabled selected>
                              Select Profession
                            </option>
                            <option value="Teachers">Teacher</option>
                            <option value="Veterans">Veteran</option>
                            <option value="Firefighter">Firefighter</option>
                            <option value="Nurse">Nurse</option>
                            <option value="Others">Other</option>
                          </select>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Medical License Number*
                          <br />
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            name=""
                            id=""
                            value={EEMLN}
                            placeholder="A1234455612"
                            onChange={(e) => handlerEMLN(e.target.value)}
                          />
                        </span>
                      </span>
                    </div>

                    <div className="col" style={{ display: "flex" }}>
                      <div className="Ep-label">
                        <span className="Ep-label">
                          Medical License Expiry Date*
                        </span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          placeholder="MM/DD/YYYY"
                          min={moment().add(1, "day").format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setEMLED(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <span className="Ep-label">
                      Drivers License Number*
                      <br />
                      <input
                        style={{ width: "100%" }}
                        type="text"
                        name=""
                        id=""
                        placeholder="DDWDLFB34445XX01223"
                        value={EDLN}
                        onChange={(e) => handlerEDLN(e.target.value)}
                      />
                    </span>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <span className="Ep-label">
                      Password*
                      <br />
                      <input
                        style={{ width: "100%" }}
                        type={showEpassword ? "text" : "password"}
                        name=""
                        id=""
                        placeholder="Enter Password"
                        value={EPassword}
                        onChange={(e) => setEPassword(e.target.value.trim())}
                      />
                      {showEpassword ? (
                        <img
                          className="eye-icon-Epassword"
                          src={eye}
                          onClick={() => {
                            setShowEpassword(!showEpassword);
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          className="eye-icon-Epassword"
                          onClick={() => {
                            setShowEpassword(!showEpassword);
                          }}
                          alt=""
                        />
                      )}
                    </span>
                    <p className="pass-auth">
                      * Password should have 8 characters including 1 Special, 1
                      Numeric, and 1 Uppercase.
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "26px",
                    }}>
                    {!EbtnLoad ? (
                      <button
                        className="LS-savebtn"
                        disabled={EbtnLoad}
                        onClick={() => {
                          CreateAccountWithEmail();
                        }}>
                        Create Account
                      </button>
                    ) : (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Updating...
                      </Button>
                    )}
                  </div>
                </div>
                <hr />
                <div className="or">
                  <p>OR</p>
                </div>
                <div className="signup-btn-ls">
                  <button
                    onClick={() => {
                      googlefirbase();
                    }}>
                    <img
                      style={{ marginRight: "48px", marginLeft: "-15px" }}
                      src={googleIcon}
                      alt="goog"
                    />
                    CONTINUE WITH GOOGLE
                  </button>

                  {/* <FacebookLogin
                    appId={config.Facebook}
                    autoLoad={false}
                    fields="name, email"
                    callback={responseFacebook}
                    render={(renderProps) => <button onClick={renderProps.onClick}><img style={{ marginRight: "48px", marginLeft: "13px" }} src={fbIcon} alt="fb" /> CONTINUE WITH FACEBOOK</button>}
                  /> */}

                  <button onClick={() => continuePhone()}>
                    <svg
                      style={{ marginRight: "48px", marginLeft: "-15px" }}
                      width="17"
                      height="31"
                      viewBox="0 0 17 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.255 0H1.74497C0.782942 0 0 0.800869 0 1.78493V29.2151C0 30.1991 0.782942 31 1.74497 31H15.255C16.2171 31 17 30.1991 17 29.2151V1.78493C17 0.800869 16.2171 0 15.255 0ZM12.3697 0.968655L12.0634 1.9322L4.93629 1.93247L4.63003 0.968924L12.3697 0.968655ZM16.0527 29.2146C16.0527 29.6644 15.695 30.0308 15.2548 30.0308L1.74538 30.0305C1.30565 30.0305 0.947446 29.6646 0.947446 29.2143V1.78487C0.947446 1.33506 1.30515 0.968661 1.74538 0.968661H3.63487L4.14213 2.5666C4.20544 2.76627 4.38719 2.90119 4.59242 2.90119H12.4077C12.6129 2.90119 12.7947 2.76628 12.858 2.5666L13.3652 0.968661H15.2547C15.6944 0.968661 16.0526 1.33455 16.0526 1.78487L16.0527 29.2146ZM8.50014 25.1674C7.59267 25.1674 6.8549 25.9227 6.8549 26.8503C6.8549 27.7786 7.59328 28.5338 8.50014 28.5338C9.4076 28.5338 10.1459 27.7785 10.1459 26.8503C10.1459 25.9223 9.40754 25.1674 8.50014 25.1674ZM8.50014 27.5648C8.11501 27.5648 7.8016 27.2443 7.8016 26.8503C7.8016 26.4563 8.11498 26.1358 8.50014 26.1358C8.88529 26.1358 9.19867 26.4563 9.19867 26.8503C9.19893 27.2445 8.88529 27.5648 8.50014 27.5648ZM10.2826 10.6757L5.78367 16.6981C5.69029 16.8227 5.54943 16.8883 5.40725 16.8883C5.30701 16.8883 5.20598 16.8559 5.11998 16.7887C4.91212 16.6263 4.87255 16.3222 5.03135 16.1098L9.53026 10.0875C9.68907 9.87487 9.98609 9.83439 10.194 9.99683C10.4016 10.1593 10.4414 10.4631 10.2826 10.6757ZM11.9695 13.2148L7.47057 19.2372C7.37719 19.3618 7.23632 19.4274 7.09415 19.4274C6.9939 19.4274 6.89287 19.395 6.80688 19.3278C6.59902 19.1654 6.55944 18.8613 6.71825 18.6489L11.2169 12.6266C11.3757 12.414 11.673 12.3735 11.8806 12.5359C12.0884 12.6981 12.128 13.0022 11.9695 13.2148Z"
                        fill="#585858"
                      />
                    </svg>
                    CONTINUE USING PHONE
                  </button>
                </div>

                <div className="LS-bottom">
                  By continuing, I agree to the{" "}
                  <span style={{ fontWeight: "500", fontSize: "14px" }}>
                    {" "}
                    <Link
                      to="/terms-and-conditions"
                      className="textColor"
                      onClick={() => {
                        lsDisplay(false);
                      }}
                      style={{ textDecoration: "none", color: "white" }}>
                      Terms &#38; Conditions
                    </Link>{" "}
                  </span>
                  &{" "}
                  <span style={{ fontWeight: "500", fontSize: "14px" }}>
                    {" "}
                    <Link
                      to="/privacypolicy"
                      className="textColor"
                      onClick={() => {
                        lsDisplay(false);
                      }}
                      style={{ textDecoration: "none", color: "white" }}>
                      Privacy Policy
                    </Link>{" "}
                  </span>
                </div>
              </>
            )}

            {phoneLogin && (
              <>
                <div
                  className="backBtn-lsModal signup-phone-backBtn"
                  onClick={() => {
                    loginSection();
                  }}>
                  {" "}
                  &lt; &nbsp;<span>Back</span>{" "}
                </div>
                <div className="LS-phoneLogin">
                  <div className="LS-PhoneLogin-text">SIGNUP USING PHONE</div>

                  <div>
                    <div className="Ep-mobilenumber-lable">
                      <span className="Ep-label" style={{ width: "119px" }}>
                        Country
                      </span>
                      <span className="Ep-label">Mobile Number</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <PhoneInput
                        className="Ep-numberNumber"
                        style={{ width: "100%" }}
                        defaultCountry="US"
                        placeholder="XXXXXXXXXXX"
                        value={value}
                        onChange={setValue}
                        error={
                          value
                            ? isValidPhoneNumber(value)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginBottom: "26px",
                      }}>
                      <button
                        className="LS-savebtn-phoneLogin"
                        onClick={(e) => {
                          sendOtp(e);
                        }}
                        disabled={value?.length < 9 || !value?.length}
                        style={{
                          background:
                            value?.length < 9 || !value?.length ? "gray" : "",
                        }}>
                        <div id="sign-in-button"></div>
                        Send OTP
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {otp && (
              <>
                <div>
                  <img
                    src={verificationCode}
                    alt="ver"
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  />
                  <p className="verificationCode-label">Verification Code</p>
                  <span className="verificationCode-txt">
                    OTP has been send to your Mobile Number. Please Verify!{" "}
                  </span>
                </div>
                <p className="verificationCode-mail">{value}</p>

                <div className="otp-form">
                  <div className="otp-code">
                    <input
                      type="tel"
                      required
                      value={otp1}
                      ref={field1}
                      onChange={(e) => {
                        handleChangeOtp1(e);
                        !otp1 ? field2.current.focus() : field1.current.focus();
                      }}
                    />
                    <input
                      type="tel"
                      required
                      value={otp2}
                      ref={field2}
                      onChange={(e) => {
                        handleChangeOtp2(e);
                        !otp2 ? field3.current.focus() : field1.current.focus();
                      }}
                    />
                    <input
                      type="tel"
                      required
                      value={otp3}
                      ref={field3}
                      onChange={(e) => {
                        handleChangeOtp3(e);
                        !otp3 ? field4.current.focus() : field2.current.focus();
                      }}
                    />
                    <input
                      type="tel"
                      required
                      value={otp4}
                      ref={field4}
                      onChange={(e) => {
                        handleChangeOtp4(e);
                        !otp4 ? field5.current.focus() : field3.current.focus();
                      }}
                    />
                    <input
                      type="tel"
                      required
                      value={otp5}
                      ref={field5}
                      onChange={(e) => {
                        handleChangeOtp5(e);
                        !otp5 ? field6.current.focus() : field4.current.focus();
                      }}
                    />
                    <input
                      type="tel"
                      required
                      value={otp6}
                      ref={field6}
                      onChange={(e) => {
                        handleChangeOtp6(e);
                        !otp6 ? field6.current.focus() : field5.current.focus();
                      }}
                      onKeyPress={(e) => submitOtp(e)}
                    />
                  </div>
                </div>
                {otpVal ? (
                  <p className="dangerColor" style={{ marginLeft: "-7%" }}>
                    Please enter correct OTP
                  </p>
                ) : null}

                <span className="verificationCode-txt">
                  Didn’t received the code?{" "}
                  <bold
                    className="textColor"
                    style={{
                      textDecorationLine: "underline",
                      fontWeight: "500",
                    }}>
                    {" "}
                    Resend{" "}
                  </bold>
                </span>

                <button
                  style={{ marginTop: "40px" }}
                  className="LoginBtn"
                  onClick={(e) => {
                    onSubmitOTP(e);
                  }}>
                  continue
                </button>
              </>
            )}

            {afterPhoneOtp && (
              <>
                <div className="profile-Create">
                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col">
                      <span className="Ep-label">
                        Full Name*
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter Name"
                          value={Pname}
                          onChange={(e) => handlerPName(e.target.value)}
                        />
                      </span>
                    </div>

                    <div className="col">
                      <span className="Ep-label">
                        Email*
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter Email"
                          value={Pemail}
                          onChange={(e) => setPemail(e.target.value.trim())}
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col">
                      <span className="Ep-label">
                        Gender*
                        <br />
                        <select
                          style={{ cursor: "pointer" }}
                          className="Ep-selectGender"
                          value={Pgender}
                          placeholder="Gender"
                          onChange={(e) => setPGender(e.target.value)}>
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Rather Not Say">Other</option>
                        </select>
                      </span>
                    </div>

                    <div
                      className="col signupCommunityDiv"
                      style={{ display: "flex" }}>
                      <span className="Ep-label">
                        Profession*
                        <br />
                        <select
                          className="Ep-selectGender"
                          style={{ cursor: "pointer" }}
                          value={PProfession}
                          placeholder="profession"
                          onChange={(e) => setPProfession(e.target.value)}>
                          <option value="" disabled selected>
                            Select Profession
                          </option>
                          <option value="Teachers">Teacher</option>
                          <option value="Veterans">Veteran</option>
                          <option value="Firefighter">Firefighter</option>
                          <option value="Nurse">Nurse</option>
                          <option value="Others">Other</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div className="col">
                      <div className="Ep-label">
                        <span className="Ep-label">Date Of Birth*</span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          value={Pdate}
                          max={moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD")}
                          type="date"
                          onChange={(e) => {
                            setPDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Medical License Number*
                          <br />
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            name=""
                            id=""
                            placeholder="A1234455612"
                            value={PEMLN}
                            onChange={(e) => {
                              handlerPMLN(e.target.value);
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div className="col">
                      <div className="Ep-label">
                        <span className="Ep-label">
                          Medical License Expiry Date*
                        </span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          value={PPMLED}
                          min={moment().add(1, "days").format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setPMLED(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Drivers License Number*
                          <br />
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            name=""
                            id=""
                            placeholder="DDWDLFB34445XX01223"
                            value={PDLN}
                            onChange={(e) => {
                              handlerPDLN(e.target.value);
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <span className="Ep-label">
                      Password*
                      <br />
                      <input
                        style={{ width: "100%" }}
                        type={showPpassword ? "text" : "password"}
                        name=""
                        id=""
                        placeholder="Enter Password"
                        value={PPassword}
                        onChange={(e) => setPPassword(e.target.value.trim())}
                      />
                      {showPpassword ? (
                        <img
                          className="eye-icon-Epassword"
                          src={eye}
                          onClick={() => {
                            setShowPpassword(!showPpassword);
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          className="eye-icon-Epassword"
                          onClick={() => {
                            setShowPpassword(!showPpassword);
                          }}
                          alt=""
                        />
                      )}
                    </span>
                    <p className="pass-auth">
                      * Password should have 8 characters including 1 Special, 1
                      Numeric, and 1 Uppercase.
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "26px",
                    }}>
                    {!PbtnLoad ? (
                      <button
                        className="LS-savebtn"
                        disabled={PbtnLoad}
                        onClick={() => {
                          CreateAccountWithPhone();
                        }}>
                        Create Account
                      </button>
                    ) : (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Updating...
                      </Button>
                    )}
                  </div>
                  <div className="LS-bottom">
                    By continuing, I agree to the{" "}
                    <span
                      style={{ fontWeight: "500", fontSize: "14px" }}
                      className="textColor">
                      &nbsp;{" "}
                      <Link
                        to="/terms-and-conditions"
                        className="textColor"
                        onClick={() => {
                          lsDisplay(false);
                        }}
                        style={{ textDecoration: "none", color: "white" }}>
                        Terms &#38; Conditions
                      </Link>{" "}
                    </span>
                    &
                    <span
                      style={{ fontWeight: "500", fontSize: "14px" }}
                      className="textColor">
                      {" "}
                      <Link
                        to="/privacypolicy"
                        className="textColor"
                        onClick={() => {
                          lsDisplay(false);
                        }}
                        style={{ textDecoration: "none", color: "white" }}>
                        Privacy Policy
                      </Link>{" "}
                    </span>
                  </div>
                </div>
              </>
            )}

            {afterFBorG && (
              <>
                <div className="profile-Create">
                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col-6  phoneW-100">
                      <span className="Ep-label">
                        Full Name*
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name=""
                          id=""
                          placeholder="Enter your Name"
                          value={Gname}
                          onChange={(e) => handlerGName(e.target.value)}
                        />
                      </span>
                    </div>

                    <div className="col-6 phoneW-100">
                      <div className="Ep-mobilenumber-lable">
                        <span className="Ep-label" style={{ width: "119px" }}>
                          Country
                        </span>
                        <span className="Ep-label">Mobile Number</span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <PhoneInput
                          className="Ep-numberNumber"
                          defaultCountry="US"
                          style={{ width: "100%" }}
                          placeholder="XXXXXXXXXX"
                          onChange={setGphoneNumber}
                          error={
                            GphoneNumber
                              ? isValidPhoneNumber(GphoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <div className="col">
                      <span className="Ep-label">
                        Gender*
                        <br />
                        <select
                          className="Ep-selectGender"
                          style={{ cursor: "pointer" }}
                          placeholder="Gender"
                          onChange={(e) => setGGender(e.target.value)}>
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Rather Not Say">Other</option>
                        </select>
                      </span>
                    </div>

                    <div className="col" style={{ display: "flex" }}>
                      <span className="Ep-label">
                        Profession*
                        <br />
                        <select
                          className="Ep-selectGender"
                          style={{ cursor: "pointer" }}
                          placeholder="Gender"
                          onChange={(e) => setGProfession(e.target.value)}>
                          <option value="" disabled selected>
                            Select Profession
                          </option>
                          <option value="Teachers">Teacher</option>
                          <option value="Veterans">Veteran</option>
                          <option value="Firefighter">Firefighter</option>
                          <option value="Nurse">Nurse</option>
                          <option value="Others">Other</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div className="col">
                      <div className="Ep-label">
                        <span className="Ep-label">Your Date Of Birth*</span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          value={Gdate}
                          max={moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setGDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Medical License Number*
                          <br />
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            name=""
                            id=""
                            placeholder="A1234455612"
                            value={GEMLN}
                            onChange={(e) => {
                              handlerGMLN(e.target.value);
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{
                      marginBottom: "22px",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <div
                      className="col signupCommunityDiv"
                      style={{ marginTop: "-15px" }}>
                      <span style={{ flexDirection: "row" }}>
                        <span className="Ep-label">
                          Drivers License Number*
                          <br />
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            name=""
                            id=""
                            placeholder="DDWDLFB34445XX01223"
                            value={GDLN}
                            onChange={(e) => {
                              handlerGDLN(e.target.value);
                            }}
                          />
                        </span>
                      </span>
                    </div>

                    <div className="col signupCommunityDiv">
                      <div className="Ep-label">
                        <span className="Ep-label">
                          Medical License Expiry Date*
                        </span>
                        <br />
                        {/* <DatePicker selected={date} onChange={handleChange} /> */}
                        <input
                          style={{ width: "100%" }}
                          type="date"
                          value={GMLED}
                          min={moment().add(1, "days").format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setGMLED(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="row signupRow-Phone"
                    style={{ marginBottom: "22px" }}>
                    <span className="Ep-label">
                      Password*
                      <br />
                      <input
                        style={{ width: "100%" }}
                        type={showGpassword ? "text" : "password"}
                        name=""
                        id=""
                        placeholder="Enter your Name"
                        value={GPassword}
                        onChange={(e) => setGPassword(e.target.value.trim())}
                      />
                      {showGpassword ? (
                        <img
                          className="eye-icon-Epassword"
                          src={eye}
                          onClick={() => {
                            setShowGpassword(!showGpassword);
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          className="eye-icon-Epassword"
                          onClick={() => {
                            setShowGpassword(!showGpassword);
                          }}
                          alt=""
                        />
                      )}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "26px",
                    }}>
                    {!GbtnLoad ? (
                      <button
                        className="LS-savebtn"
                        disabled={GbtnLoad}
                        onClick={() => {
                          CreateAccountWithGoogle();
                        }}>
                        Create Account
                      </button>
                    ) : (
                      <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Updating...
                      </Button>
                    )}
                  </div>
                  <div className="LS-bottom">
                    By continuing, I agree to the{" "}
                    <Link
                      to="/terms-and-conditions"
                      className="textColor"
                      onClick={() => {
                        lsDisplay(false);
                      }}
                      style={{ textDecoration: "none", color: "white" }}>
                      Terms &#38; Conditions
                    </Link>{" "}
                    &
                    <Link
                      to="/privacypolicy"
                      className="textColor"
                      onClick={() => {
                        lsDisplay(false);
                      }}
                      style={{ textDecoration: "none", color: "white" }}>
                      Privacy Policy
                    </Link>{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LSModal;
