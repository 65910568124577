import { useEffect, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { Tooltip } from "@nextui-org/react";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import greenPlaceholder from "../../images/Green_placeholder.png";
import { ChatContext } from "../../Contexts/ChatContext";

import "../../App.css";

function CategoriesCard(props) {
  const { darkMode } = useContext(ChatContext);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!props?.image) setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Link
        to={"/products/category/" + props.categoryID}
        style={{ textDecoration: "none" }}>
        <Card
          className="phonecat catHome"
          style={{
            background: darkMode ? "#20262D" : "#FFFFFF",
          }}>
          {!props.image ? (
            <Card.Img
              variant="bottom"
              style={{
                width: "100px",
                height: "100px",
                alignSelf: "center",
                marginBottom: "20px",
              }}
              src={noimg}
            />
          ) : (
            <Card.Img
              variant="bottom"
              style={{
                width: "130px",
                height: "125px",
                alignSelf: "center",
                // marginBottom: "10%",
                display: !isLoaded ? "none" : "flex",
              }}
              src={props.image}
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          )}
          {!isLoaded ? (
            <Card.Img
              variant="bottom"
              style={{
                width: "76px",
                height: "76px",
                alignSelf: "center",
                marginBottom: "10%",
              }}
              src={greenPlaceholder}
            />
          ) : (
            ""
          )}

          {props?.name?.length < 20 ? (
            <Card.Text>{props?.name ? props?.name : "N/A"}</Card.Text>
          ) : (
            <Tooltip
              content={props?.name}
              color="success"
              rounded={false}
              placement={"top"}>
              <Card.Text>{props?.name?.substring(0, 20)}...</Card.Text>
            </Tooltip>
          )}
        </Card>
      </Link>
    </>
  );
}

export default CategoriesCard;
