import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import moment from "moment";
require("moment-timezone");

export default function DealsCard(props) {
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const endsOn = props?.deals?.outletBasisConfig[chainID].endsOn;
  const endDate = moment.tz(moment(endsOn), props.timeZone);
  const duration = moment.duration(endDate.diff(moment()));
  const daysLeft = Math.ceil(duration.asDays());

  let timeLeft;
  if (daysLeft === 1) {
    timeLeft = "1 day left";
  } else {
    timeLeft = `${daysLeft} days left`;
  }
  //   console.log(props?.deals?.images);
  return (
    <Card
      style={{
        margin: "20px",
        width: "250px",
        height: "360px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
      }}>
      <div className="dealCard-body">
        {props?.deals?.outletBasisConfig[chainID].neverExpires ? null : (
          <div className="deals-endsin-div">
            <div className="deals-endsin">{timeLeft}</div>
          </div>
        )}
        <div className="dealCard-img">
          {props?.deals?.images.length > 0 ? (
            <img src={props?.deals?.images} alt="dealimg" />
          ) : (
            <img src={noimg} alt="dealimg" />
          )}
        </div>

        <div className="dealCard-dealType">{props?.deals?.dealType}</div>

        <span className="dealCard-name">{props?.deals?.name}</span>

        {/* <span className="dealCard-brand">Ceres Garden</span> */}

        <div>
          {props?.deals?.dealType === "REGULAR" ? (
            <Link
              to={"/deals/regular/" + props?.deals?.slug}
              style={{ textDecoration: "none" }}>
              <div
                style={{ width: "100%" }}
                className="veiw-product-btn-home cursor">
                View Deal
              </div>
            </Link>
          ) : (
            <Link
              to={"/deals/" + props?.deals?.slug}
              style={{ textDecoration: "none" }}>
              <div
                style={{ width: "100%" }}
                className="veiw-product-btn-home cursor">
                View Deal
              </div>
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
}
