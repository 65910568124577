import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import url from "../../config/axios";
import config from "../../config";
import DealRegular from "./DealRegular";
import TopHeader from "../TopHeader/TopHeader";
import Swal from "sweetalert2";
import EmptiedWishlist from "../Profile/EmptiedWishlist";

function ProductItem() {
  const [dealDetial, setDealDetial] = useState([]);
  const [deal, setDeal] = useState([]);

  // console.log(deal.timezone);
  const params = useParams();
  let dealSlug = params.dealSlug;

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  useEffect(() => {
    getDealsDetials();
  }, []);

  const getDealsDetials = () => {
    url
      .get(
        `v1/typed-deals-users/get-single-by-slug?outletChainID=${chainID}&consumerID=${config.consumerId}&slug=${dealSlug}`,
        {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
          },
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setDealDetial(res.data.data.inventories);
          setDeal(res.data.data);
        } else {
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e.response.data.data.message, "warning");
        // console.log(e.response);
      });
  };

  // console.log(deal);

  return (
    <>
      <TopHeader pageName={"Deals"} />
      {deal?.deal ? (
        <div className="DealProduct-Maindiv">
          <div className="DealProduct-div">
            {dealDetial.map((e, i) => {
              return (
                <DealRegular
                  product={e}
                  timeZone={deal.timezone}
                  deal={deal.deal}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <EmptiedWishlist message={"Deal"} />
      )}
    </>
  );
}

export default ProductItem;
