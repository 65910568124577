import TopHeader from "../../components/TopHeader/TopHeader";
import "../../components/Deals/Deals.css";
import DealsPage from "../../components/Deals/DealsPage";
function Deals() {
  return (
    <>
      <TopHeader pageName={"Deals"} />
      <div style={{ width: "100%" }}>
        <DealsPage />
      </div>
    </>
  );
}

export default Deals;
