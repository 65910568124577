import { Link } from "react-router-dom";
import url from "../config/axios";
import config from "../config";
import { useEffect, useState, useContext } from "react";
import fb from "../Icons/newFacebook.svg";
import insta from "../Icons/instaC.svg";
import googlePlus from "../Icons/newgoogleplus.svg";
import tiktok from "../Icons/tiktokC.svg";
import twitter from "../Icons/newTwitter.svg";
import youtube from "../Icons/youtubeC .svg";
import appStore from "../Icons/newAppStoreButton.svg";
import Bleaum from "../images/bleaum.png";
import googlePlay from "../Icons/newGooglePlay.svg";
import { ChatContext } from "../Contexts/ChatContext";
import Spinner from "react-bootstrap/Spinner";

import "../App.css";

let logo = require("../images/" + config.footerLogo);

function Footer() {
  const { setLoginModal } = useContext(ChatContext);

  const [footerLogo, setFooterLogo] = useState([]);
  const [footerFb, setFooterFb] = useState([]);
  const [footerInsta, setFooterInsta] = useState([]);
  const [footerGooglePlus, setFooterGooglePlus] = useState([]);
  const [footerTwitter, setFooterTwitter] = useState([]);
  const [footerYoutube, setFooterYoutube] = useState([]);
  const [footerLinkedIn, setFooterLinkedIn] = useState([]);
  const [footerTiktok, setFooterTiktok] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const outletAddress = JSON.parse(localStorage.getItem("outletAddress"));
  const helpContact = JSON.parse(localStorage.getItem("helpContact"));

  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    url.get(`/v1/general-settings/info`).then((res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setFooterLogo(res?.data?.data?.media?.footerLogoFileSource);
        if (!res?.data?.data?.media?.footerLogoFileSource) setIsLoaded(true);
        res?.data?.data?.social?.links.map((e, i) => {
          if (e.medium === "facebook") setFooterFb(e);
          if (e.medium === "twitter") setFooterTwitter(e);
          if (e.medium === "instagram") setFooterInsta(e);
          if (e.medium === "youtube") setFooterYoutube(e);
          if (e.medium === "linkedIn") setFooterLinkedIn(e);
          if (e.medium === "web") setFooterGooglePlus(e);
          if (e.medium === "tiktok") setFooterTiktok(e);
        });
      } else {
        // alert("Not", res.data.data);
      }
    });
  }, []);

  //console.log(footerFb);

  return (
    <>
      {/* // *************************** Mobile VEIW **********************/}
      <div className="footer-main hide-web" style={{ maxWidth: "100%" }}>
        <div className="container">
          <div className="row" style={{ padding: "40px", textAlign: "center" }}>
            <div className="col-12" style={{ textAlign: "left" }}>
              <div className="logo-footer">
                {!isLoaded ? (
                  <Spinner
                    style={{ width: "60px", height: "60px" }}
                    animation="grow"
                    variant="light"
                  />
                ) : (
                  ""
                )}
                <img
                  className="Flogo"
                  src={footerLogo ? footerLogo : logo}
                  onLoad={() => {
                    setIsLoaded(true);
                  }}
                  style={{
                    display: !isLoaded ? "none" : "",
                  }}
                  alt=""
                />
              </div>
              <div className="add1" style={{ width: "100%" }}>
                {outletAddress ? outletAddress : config.Address}
              </div>
              <br />
              <div className="add2" style={{ paddingTop: "0px" }}>
                {helpContact ? helpContact : config.Contact_number}
              </div>
              <ul
                className="socialiconslist"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingLeft: "0px",
                  paddingRigtt: "0px",
                }}>
                {footerGooglePlus.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerGooglePlus.link}>
                    {" "}
                    {footerGooglePlus.link ? (
                      <img
                        className="s-icons"
                        src={googlePlus}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerInsta.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerInsta.link}>
                    {" "}
                    {footerInsta.link ? (
                      <img className="s-icons" src={insta} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerFb.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerFb.link}>
                    {" "}
                    {footerFb.link ? (
                      <img className="s-icons" src={fb} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerTwitter.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerTwitter.link}>
                    {" "}
                    {footerTwitter.link ? (
                      <img
                        className="s-icons"
                        src={twitter}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerYoutube.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerYoutube.link}>
                    {" "}
                    {footerYoutube.link ? (
                      <img
                        className="s-icons"
                        src={youtube}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerTiktok.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerTiktok.link}>
                    {" "}
                    {footerTiktok.link ? (
                      <img className="s-icons" src={tiktok} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
              </ul>
            </div>

            <div className="col-6 ">
              <ul>
                <li style={{ fontWeight: "600" }}>Company</li>
                {/* <li>About Us</li> */}
                {/* <li>News & Blogs</li> */}
                <li>
                  <Link
                    to="/contact-us"
                    style={{ textDecoration: "none", color: "white" }}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    style={{ textDecoration: "none", color: "white" }}>
                    Terms &#38; Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacypolicy"
                    style={{ textDecoration: "none", color: "white" }}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6">
              <ul>
                <li style={{ fontWeight: "600" }}>Quick Links</li>

                <li>
                  {" "}
                  <Link
                    to="/categories"
                    style={{ textDecoration: "none", color: "white" }}>
                    Categories
                  </Link>
                </li>
                <li>
                  <Link
                    to="/deals"
                    style={{ textDecoration: "none", color: "white" }}>
                    Deals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manufacturers"
                    style={{ textDecoration: "none", color: "white" }}>
                    Manufacturers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "white" }}>
                    Login / Sign Up
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-6">
              <ul>
                <li style={{ fontWeight: "600" }}>Support</li>
                <li>Get Help</li>
                <li>FAQs</li>
                <li>Find A Store</li>
                <li>Chat With Us</li>
              </ul>
            </div> */}

            <div className="col " style={{ alignSelf: "center" }}>
              <ul className="leftFooterLogo ">
                <li>
                  <li style={{ fontWeight: "600" }}>Download Our App</li>
                  <ul className="store-mobile">
                    <Link
                      to={"/app-details"}
                      style={{ textDecoration: "none" }}>
                      <li>
                        {" "}
                        <img src={googlePlay} alt="storeicons" />{" "}
                      </li>
                    </Link>
                    {/* <a
                      rel="noreferrer"
                      target="_blank"
                      href={config?.GooglePlay}>
                      {" "}
                      <li>
                        {" "}
                        <img src={googlePlay} alt="storeicons" />{" "}
                      </li>
                    </a> */}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={config?.PlayStore}>
                      {" "}
                      <li>
                        {" "}
                        <img src={appStore} alt="storeicons" />{" "}
                      </li>
                    </a>

                    {/* <Link
                      to={"/app-details"}
                      style={{ textDecoration: "none" }}>
                      <li>
                        {" "}
                        <img src={appStore} alt="storeicons" />{" "}
                      </li>
                    </Link> */}
                  </ul>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.bleaum.io/ ">
                    <img src={Bleaum} alt="soicalicons" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-6 " style={{ alignSelf: "center" }}>
              <ul className="leftFooterLogo ">
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.bleaum.io/ ">
                    <img src={Bleaum} alt="soicalicons" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="copyright" style={{ textAlign: "center" }}>
            <span style={{ color: "white" }}>
              © {config.name} {year}. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>

      {/* // *************************** WEB VEIW ************************/}

      <div className="footer-main hide" style={{ maxWidth: "100%" }}>
        <div className="container">
          <div className="row" style={{ padding: "40px", textAlign: "center" }}>
            <div className="col" style={{ textAlign: "left" }}>
              <div className="logo-footer">
                {!isLoaded ? (
                  <Spinner
                    style={{ width: "60px", height: "60px" }}
                    animation="grow"
                    variant="light"
                  />
                ) : (
                  ""
                )}
                <img
                  className="Flogo"
                  src={footerLogo ? footerLogo : logo}
                  onLoad={() => {
                    setIsLoaded(true);
                  }}
                  style={{
                    display: !isLoaded ? "none" : "",
                  }}
                  alt=""
                />
              </div>
              <div className="add1">
                {outletAddress ? outletAddress : config.Address}
              </div>
              <br />
              <div className="add2">
                {helpContact ? helpContact : config.Contact_number}
              </div>
              <ul
                className="socialiconslist"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "20px",
                  paddingLeft: "0px",
                  paddingRigtt: "0px",
                }}>
                {footerGooglePlus.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerGooglePlus.link}>
                    {" "}
                    {footerGooglePlus.link ? (
                      <img
                        className="s-icons"
                        src={googlePlus}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerInsta.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerInsta.link}>
                    {" "}
                    {footerInsta.link ? (
                      <img className="s-icons" src={insta} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerFb.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerFb.link}>
                    {" "}
                    {footerFb.link ? (
                      <img className="s-icons" src={fb} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerTwitter.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerTwitter.link}>
                    {" "}
                    {footerTwitter.link ? (
                      <img
                        className="s-icons"
                        src={twitter}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerYoutube.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerYoutube.link}>
                    {" "}
                    {footerYoutube.link ? (
                      <img
                        className="s-icons"
                        src={youtube}
                        alt="soicalicons"
                      />
                    ) : null}{" "}
                  </a>
                ) : null}
                {footerTiktok.link ? (
                  <a
                    style={{ marginRight: "10px" }}
                    rel="noreferrer"
                    target="_blank"
                    href={footerTiktok.link}>
                    {" "}
                    {footerTiktok.link ? (
                      <img className="s-icons" src={tiktok} alt="soicalicons" />
                    ) : null}{" "}
                  </a>
                ) : null}
              </ul>
            </div>

            <div className="col Company-mobile">
              <ul>
                <li style={{ fontWeight: "600" }}>Company</li>
                {/* <li>About Us</li> */}
                {/* <li>News & Blogs</li> */}
                <li>
                  <Link
                    to="/contact-us"
                    style={{ textDecoration: "none", color: "white" }}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    style={{ textDecoration: "none", color: "white" }}>
                    Terms &#38; Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacypolicy"
                    style={{ textDecoration: "none", color: "white" }}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <ul>
                <li style={{ fontWeight: "600" }}>Quick Links</li>

                <li>
                  {" "}
                  <Link
                    to="/categories"
                    style={{ textDecoration: "none", color: "white" }}>
                    Categories
                  </Link>
                </li>
                <li>
                  <Link
                    to="/deals"
                    style={{ textDecoration: "none", color: "white" }}>
                    Deals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manufacturers"
                    style={{ textDecoration: "none", color: "white" }}>
                    Manufacturers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setLoginModal(true);
                    }}
                    style={{ textDecoration: "none", color: "white" }}>
                    Login / Sign Up
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="col">
            <ul>
              <li style={{ fontWeight: "600" }}>Support</li>
              <li>Get Help</li>
              <li>FAQs</li>
              <li>Find A Store</li>
              <li>Chat With Us</li>
            </ul>
          </div>   */}

            <div className="col " style={{ alignSelf: "center" }}>
              <ul className="leftFooterLogo ">
                <li>
                  <li style={{ fontWeight: "600" }}>Download Our App</li>
                  <ul className="store-mobile">
                    <Link
                      to={"/app-details"}
                      style={{ textDecoration: "none" }}>
                      <li>
                        {" "}
                        <img src={googlePlay} alt="storeicons" />{" "}
                      </li>
                    </Link>
                    {/* <a
                      rel="noreferrer"
                      target="_blank"
                      href={config?.GooglePlay}>
                      {" "}
                      <li>
                        {" "}
                        <img src={googlePlay} alt="storeicons" />{" "}
                      </li>
                    </a> */}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={config?.PlayStore}>
                      {" "}
                      <li>
                        {" "}
                        <img src={appStore} alt="storeicons" />{" "}
                      </li>
                    </a>

                    {/* <Link
                      to={"/app-details"}
                      style={{ textDecoration: "none" }}>
                      <li>
                        {" "}
                        <img src={appStore} alt="storeicons" />{" "}
                      </li>
                    </Link> */}
                  </ul>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.bleaum.io/ ">
                    <img src={Bleaum} alt="soicalicons" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="copyright" style={{ textAlign: "center" }}>
            <span style={{ color: "white" }}>
              © {config.name} {year}. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
