import NavBar from "../components/Homepage/NavBar.jsx";

function Homepage() {
  return (
    <>
      <NavBar />
    </>
  );
}

export default Homepage;
