import { useContext, useState, useEffect } from "react";
import "./CustomSwitch.css";
import { ChatContext } from "../../Contexts/ChatContext";

export default function CustomSwitch() {
  const { setDarkMode } = useContext(ChatContext);
  const dkmode = JSON.parse(localStorage.getItem("darkMode"));

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(dkmode);
  }, []);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    let checkValue = !isChecked ? true : false;
    setDarkMode(checkValue);
    localStorage.setItem("darkMode", JSON.stringify(checkValue));
  };

  return (
    <div className="wrapper">
      <input
        type="checkbox"
        id="hide-checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
      <label htmlFor="hide-checkbox" className="toggle">
        <span className="toggle-button"></span>
      </label>
    </div>
  );
}
