import { useEffect, useState } from "react";
import config from "../../config";
import url from "../../config/axios";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import QRCodeGenerator from "./QRCodeGenerator";
import "./AppDetial.css";
import moment from "moment";

let logo = require("../../images/" + config.footerLogo);

export default function AppDetial() {
  const [appLogo, setAppLogo] = useState([]);
  const [dealData, setDealData] = useState([]);
  const [appdownloadLink, setAppdownloadLink] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  console.log(dealData);
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  useEffect(() => {
    downloadApp();
    getDealsData();
  }, []);

  const getDealsData = () => {
    url
      .get(`/v1/app-settings/app?consumerID=${config.consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setAppLogo(res.data.data.app?.appIcon);
          await setDealData(res.data.data.app);
        } else {
          Swal.fire("Oops", res.data.data.Message, "warning");
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e?.response?.data?.data?.message, "warning");
        // console.log(e.response);
      });
  };

  const downloadApp = () => {
    url
      .get(`v1/apk-records/get-record?consumerID=${config.consumerId}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
        },
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          setAppdownloadLink(res?.data?.data?.record?.downloadLink);
        } else {
          Swal.fire("Oops", res.data.data.Message, "warning");
        }
      })
      .catch((e) => {
        Swal.fire("Oops", e.response.data.data.message, "warning");
      });
  };

  return (
    <div className="AppDetial-mainDiv">
      <div className="row">
        <div className="col">
          <div className="AppDetial-nameDiv">
            <span className="AppDetial-name">{config.name}</span>
            <span className="AppDetial-subdec">
              User must be 18+ to use this app
            </span>

            <a target="_blank" href={appdownloadLink}>
              <div className="AppDetial-install">
                <div style={{ marginRight: "14px", marginBottom: "5px" }}>
                  <svg
                    width="13"
                    height="17"
                    viewBox="0 0 13 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.83465 16.7512C6.20226 17.083 6.79781 17.083 7.16537 16.7512L12.72 11.7459C13.0933 11.4084 13.0933 10.8614 12.72 10.5239C12.3466 10.1864 11.7412 10.1864 11.3678 10.5239L7.43033 14.087V0.855515C7.43033 0.38301 7.00652 0 6.48367 0C5.96082 0 5.53701 0.383 5.53701 0.855515V14.087L1.63223 10.5239C1.25884 10.1864 0.653461 10.1864 0.280045 10.5239C-0.0933483 10.8613 -0.0933483 11.4084 0.280045 11.7459L5.83465 16.7512Z"
                      fill="white"
                    />
                  </svg>
                </div>
                Download
              </div>
            </a>
          </div>

          <div className="AppDetial-ssDiv">
            {dealData?.screenShots?.map((e, i) => {
              return <img className="AppDetial-ss" src={e} alt={e} />;
            })}
            {/* <img className="AppDetial-ss" src={img1} alt="appimg1" />
            <img className="AppDetial-ss" src={img2} alt="appimg2" />
            <img className="AppDetial-ss" src={img3} alt="appimg3" />
            <img className="AppDetial-ss" src={img4} alt="appimg4" /> */}
          </div>

          <div className="AppDetial-aboutDiv">
            <span className="AppDetial-title">About this App</span>
            <span
              className="AppDetial-detial"
              dangerouslySetInnerHTML={{ __html: dealData?.description }}
            />
          </div>

          <div className="row" style={{ marginTop: "60px" }}>
            <div className="col-4 AppDetial-versionDiv">
              <span className="AppDetial-version-Title">Version</span>
              <span className="AppDetial-version-value">
                {dealData?.version}
              </span>
            </div>

            <div className="col-4 AppDetial-versionDiv">
              <span className="AppDetial-version-Title">Requires Android</span>
              <span className="AppDetial-version-value">
                {dealData?.androidVersion}
              </span>
            </div>

            <div className="col-4 AppDetial-versionDiv">
              <span className="AppDetial-version-Title">Age</span>
              <span className="AppDetial-version-value">
                {dealData?.age} users
              </span>
            </div>

            <div
              className="col-4 AppDetial-versionDiv"
              style={{ marginTop: "60px" }}>
              <span className="AppDetial-version-Title">Released on</span>
              <span className="AppDetial-version-value">
                {moment(dealData?.releaseDate).format("MMM DD, YYYY")}
              </span>
            </div>

            <div
              className="col-4 AppDetial-versionDiv"
              style={{ marginTop: "60px" }}>
              <span className="AppDetial-version-Title">Updated on</span>
              <span className="AppDetial-version-value">
                {moment(dealData?.updatedAt).format("MMM DD, YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="col AppDetial-left">
          <div className="AppDetial-leftDiv">
            <div className="AppDetial-logoDiv">
              {!isLoaded ? (
                <Spinner
                  style={{ width: "60px", height: "60px" }}
                  animation="grow"
                  variant="light"
                  className="colorBackground"
                />
              ) : (
                ""
              )}
              <img
                className="AppDetial-logo"
                src={appLogo ? appLogo : logo}
                alt="applogo"
                onLoad={() => {
                  setIsLoaded(true);
                }}
                style={{
                  display: !isLoaded ? "none" : "",
                }}
              />
            </div>
            <div className="AppDetial-logoQR">
              <QRCodeGenerator link={appdownloadLink} />
            </div>
            <a target="_blank" href={appdownloadLink}>
              <span className="AppDetial-QR">Scan QR to Install</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
