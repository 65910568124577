import React, { useEffect, useState } from "react";
import config from "../../config";
import url from "../../config/axios";
import EC from "../../Icons/extra-credit.png";
import festivals from "../../Icons/festivals.png";
import Extradis from "../../Icons/price-tag 1.svg";
import Rstore from "../../Icons/Retials-store.png";
import waves from "../../Icons/Waves.png";
import wallet from "../../Icons/wallet3d.png";
import crown from "../../Icons/crown.svg";

import "./LoyaltyPointsProfile.css";

export default function LoyaltyPointsProfile() {
  const user = JSON.parse(localStorage.getItem("user"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  const [loyaltyPoints, setLoyaltyPoints] = useState([]);
  const [pointsToPrice, setpointsToPrice] = useState(0);

  useEffect(() => {
    if (loggedIn) {
      getpoints();
      getPointsPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getpoints = () => {
    url
      .put(
        `/v1/user-loyalty-points/points-info`,
        {
          userID: user[0]?.userID,
          consumerID: config.consumerId,
        },
        {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
          },
        }
      )
      .then((res) => {
        if (res.data.Message === "Success") {
          setLoyaltyPoints(res.data.data);
        } else {
          //  alert("Not", res.data.data);
        }
      });
  };

  const getPointsPrice = () => {
    url.get(`/loyalty?consumerId=${config.consumerId}`).then((res) => {
      if (res.data.Message === "Success") {
        setpointsToPrice(res?.data?.data?.pointsToPrice);
        //setLoyaltyPoints(res.data.data);
      } else {
        // alert("Not", res.data.data);
      }
    });
  };

  return (
    <div className="Lp-details">
      <div className="Lpoints">
        <div className="col hide-web" style={{ marginBottom: "74px" }}>
          <img src={waves} alt="waves" style={{ width: "100%" }} />
          <img className="Lp-wallet" src={wallet} alt="wallet" />
        </div>
        <div className="row">
          <div className="col">
            <p className="Lp-toptitle">
              PAY AND SAVE MORE WITH LOYALTY CREDITS
              <img
                className="Lp-crown hide"
                src={crown}
                alt="crown"
                style={{ width: "100%" }}
              />
            </p>

            <div>
              <p className="points-Are">
                Your currently redeemable loyalty points are:
              </p>

              <span className="total-Lpoints">
                {loyaltyPoints?.points
                  ? parseInt(loyaltyPoints?.points)
                  : "Your points"}{" "}
                = $
                {loyaltyPoints?.worth
                  ? parseFloat(loyaltyPoints?.worth).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div className="col hide">
            <img src={waves} alt="waves" style={{ width: "100%" }} />
            <img className="Lp-wallet" src={wallet} alt="wallet" />
          </div>
        </div>
      </div>
      <div className="lowerDiv">
        <div className="ExtraDis">
          <img src={Extradis} alt="extra" />
          <p>Unlock Extra Discount & Low Price</p>
        </div>

        <div className="ExtraDis">
          <img src={EC} alt="extra" />
          <p>Use Extra Credits as Loyalty Points</p>
        </div>

        <div className="ExtraDis">
          <img src={festivals} alt="extra" />
          <p>Earn points on festivals and holidays</p>
        </div>

        <div className="ExtraDis">
          <img src={Rstore} alt="extra" />
          <p>Enjoy Benefits at Our Retail Stores</p>
        </div>
      </div>
    </div>
  );
}
