import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader/TopHeader";
import config from "../config";

import url from "../config/axios";

const PrivacyPolicy = () => {
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  const [Privacy, setPrivacy] = useState("");

  useEffect(() => {
    url
      .get(`page/id?page=privacy&consumerId=${config.consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setPrivacy(res.data.data.body);
        } else {
        }
      });
  }, [chainID]);

  return (
    <>
      <TopHeader pageName={"Privacy Policy"} />
      <div
        className="container"
        style={{ maxWidth: "940px", overflow: "hidden" }}>
        <div style={{ fontFamily: "Oswald" }}>
          {/* <h3 style={{ color: "var(--primary)" }}>Privacy Policy</h3> */}
          <h6 className="mt-2 mb-4 ">
            {/* Subtitle :{" "} */}
            <span
              className="SubtitlePP-TC"
              style={{ fontSize: "16px", fontWeight: "400" }}>
              Privacy Policy for {config.name} Bud & Bloom 2022
            </span>
          </h6>
          <div
            className="phoneTnB"
            dangerouslySetInnerHTML={{ __html: Privacy }}
          />

          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse
            faucibus interdum posuere lorem ipsum. Consectetur adipiscing elit ut aliquam purus. Faucibus et molestie ac feugiat sed lectus vestibulum
            mattis ullamcorper. Viverra mauris in aliquam sem fringilla ut. Varius vel pharetra vel turpis nunc eget lorem dolor. Elementum tempus
            egestas sed sed risus pretium quam. Nunc faucibus a pellentesque sit amet porttitor eget dolor morbi. In massa tempor nec feugiat nisl
            pretium fusce id. Nec feugiat in fermentum posuere urna nec.
          </p>
          <p>
            Blandit libero volutpat sed cras ornare arcu dui vivamus. Platea dictumst vestibulum rhoncus est pellentesque. Nullam vehicula ipsum a
            arcu cursus vitae congue mauris. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Sed enim ut sem viverra aliquet
            eget sit amet tellus. Lectus mauris ultrices eros in cursus turpis massa.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse
            faucibus interdum posuere lorem ipsum. Consectetur adipiscing elit ut aliquam purus. Faucibus et molestie ac feugiat sed lectus vestibulum
            mattis ullamcorper. Viverra mauris in aliquam sem fringilla ut. Varius vel pharetra vel turpis nunc eget lorem dolor. Elementum tempus
            egestas sed sed risus pretium quam. Nunc faucibus a pellentesque sit amet porttitor eget dolor morbi. In massa tempor nec feugiat nisl
            pretium fusce id. Nec feugiat in fermentum posuere urna nec.
          </p>
          <p>
            Blandit libero volutpat sed cras ornare arcu dui vivamus. Platea dictumst vestibulum rhoncus est pellentesque. Nullam vehicula ipsum a
            arcu cursus vitae congue mauris. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Sed enim ut sem viverra aliquet
            eget sit amet tellus. Lectus mauris ultrices eros in cursus turpis massa.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
