import React from "react";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import { Tooltip } from "@nextui-org/react";
import { Link } from "react-router-dom";

export default function DealIncludes(props) {
  console.log(props);
  return (
    <div className="DealDetial-dealInc">
      <img src={noimg} alt="noimg" />
      <Link
        to={"/product/" + props?.products?.productID}
        style={{ textDecoration: "none" }}>
        <span className="DealDetial-dealInc-name">
          {props?.products?.product?.name >= 25 ? (
            props?.products?.product?.name
          ) : (
            <span className="DealDetial-dealInc-name">
              <Tooltip
                content={props?.products?.product?.name}
                color="success"
                rounded={false}
                placement={"top"}>
                {props?.products?.product?.name?.substring(0, 25) + "..."}
              </Tooltip>
            </span>
          )}
        </span>
      </Link>
    </div>
  );
}
