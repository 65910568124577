// import firebase from "firebase/app";
import config from "../config";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// bleaum.json
const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
  measurementId: config.measurementId,
};

//console.log(config.apiKey);
//For Local production
// const firebaseConfig = {
//   apiKey: "AIzaSyDmfKHeB3d8_Ab_6ROMi5fEglcSqMmTXmQ",
//   authDomain: "growflow-3150e.firebaseapp.com",
//   projectId: "growflow-3150e",
//   storageBucket: "growflow-3150e.appspot.com",
//   messagingSenderId: "775855219812",
//   appId: "1:775855219812:web:8fc09b29a7223f4c556b71",
//   measurementId: "G-MB9B5SZZQ0",
// };

//happydaze
// const firebaseConfig = {
//   apiKey: "AIzaSyA5eeQM4NRWsq040VVRljMnuyPYzjVH-r8",
//   authDomain: "happy-daze-mobile-app.firebaseapp.com",
//   projectId: "happy-daze-mobile-app",
//   storageBucket: "happy-daze-mobile-app.appspot.com",
//   messagingSenderId: "654931963707",
//   appId: "1:654931963707:web:dea19e00531de54d4d511e",
//   measurementId: "G-HPE8MRRTR6"
// };

//doobies
// const firebaseConfig = {
//   apiKey: "AIzaSyB_6DrdT7NiJQETPhOCfMIcONzb4dX2EQA",
//   authDomain: "doobies-mobile-app.firebaseapp.com",
//   projectId: "doobies-mobile-app",
//   storageBucket: "doobies-mobile-app.appspot.com",
//   messagingSenderId: "79844808229",
//   appId: "1:79844808229:web:34c35b3dddb28365a8d1cc",
//   measurementId: "G-8KCL4G5TXL"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);
// var storage = firebase.storage();

export { firebase, storage };
