import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../src/config";
import Footer from "./components/Footer";
import url from "./config/axios";
import { ChatContext } from "./Contexts/ChatContext";
import Header from "./pages/Homepage";
import ScrollToTop from "./pages/ScrollToTop";
import Routes from "./Routes/Routes";
import { useHistory } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

let logo = require("../src/images/" + config.favIcon);

export default function App() {
  let history = useHistory();
  const dkmode = JSON.parse(localStorage.getItem("darkMode"));
  const uid = JSON.parse(localStorage.getItem("user"));
  if (uid === null) {
    localStorage.removeItem("user");
  }
  const token = JSON.parse(localStorage.getItem("Etoken"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  let loggedIn = localStorage.getItem("user") && uid !== null ? true : false;
  const [chatUs, setChatUs] = useState(false);
  const [wishCount, setWishCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [prodChat, setProdChat] = useState([]);
  const [footerLogo, setFooterLogo] = useState([]);
  const [topHeader, setTopHeader] = useState([]);
  const [LoginModal, setLoginModal] = useState(false);
  const [redirectUser, setRedirectUser] = useState([]);
  const [redirectChainID, setRedirectChainID] = useState();
  const [redirectOutletName, setRedirectOutletName] = useState("");
  const [couponsProducts, setCouponsProducts] = useState([]);
  const [couponDiscountValue, setCouponDiscountValue] = useState([]);
  const [couponDiscountPercentage, setCouponDiscountPercentage] = useState([]);
  const [mapLocation, setMapLocation] = useState([]);
  const [userData, setUserData] = useState([]);
  const [featuredProductId, setFeaturedProductId] = useState();
  const [darkMode, setDarkMode] = useState(false);
  const [regularProduct, setRegularProduct] = useState([]);

  //console.log(userData);

  useEffect(() => {
    setDarkMode(dkmode);
    url.get(`/v1/general-settings/info`).then(async (res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setFooterLogo(res?.data?.data?.media?.faviconLogoFileSource);
        setMapLocation(res?.data?.data?.info?.location.coordinates);
      } else {
        // alert("Not", res.data.data);
      }
    });

    updateCart();
    getWishItems();
  }, []);

  useEffect(() => {
    updateCart();
  }, [uid]);

  //console.log(wishCount);
  let updateCart = () => {
    if (!loggedIn) return;
    url
      .get(`/v1/user-cart/get-plain?outletChainID=${chainID}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setCartCount(res?.data?.data?.cart?.items?.length);
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };

  const getWishItems = () => {
    if (!loggedIn) return;
    url
      .get(`/v1/user-wishlists/get-all-items`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          setWishCount(res.data.data.wishLists.length);
        } else {
          //alert(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return Swal.fire(
            "Oops! ",
            "Session Expired! Please sign in again",
            "warning"
          ).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("user");
              history.push("/");
            }
          });
        }
      });
  };

  if (config.debugging) console.log = function () {};

  useEffect(() => {
    document.title = config.name;
    const root = document.documentElement;
    localStorage.setItem("vendor", config.key);

    root.style.setProperty("--primary", darkMode ? "#0D1116" : config.primary);
    root.style.setProperty(
      "--Secondary",
      darkMode ? "#20262D" : config.Secondary
    );
    root.style.setProperty("--textSecondary", config.textSecondary);
    root.style.setProperty("--DangerSecondary", config.DangerSecondary);
    root.style.setProperty("--CardColor", config.CardColor);
    root.style.setProperty("--DiscountPercentage", config.DiscountPercentage);
    root.style.setProperty("--Text", darkMode ? "#FFFFFF" : config.primary);
    //Dark theme
    root.style.setProperty("--Darkprimary", "#0D1116");
    root.style.setProperty("--DarkSecondary", "#20262D");
    root.style.setProperty(
      "--IconColor",
      darkMode ? "#FFFFFF" : config.primary
    );
    root.style.setProperty(
      "--DarkIconColor",
      darkMode ? "#0D1116" : config.primary
    );
    root.style.setProperty("--Container", darkMode ? "#0D1116" : "#FFFFFF");
    root.style.setProperty("--Card", darkMode ? "#20262D" : "#F7FAFC");
    root.style.setProperty("--darkModal", darkMode ? "#21262D" : "#F7FAFC");
    root.style.setProperty("--CardText", darkMode ? "#FFFFFF" : "#000000");
    root.style.setProperty(
      "--DarkSecondaryCardText",
      darkMode ? "#7E8A98" : "#2B302D"
    );
    root.style.setProperty(
      "--DarkSecondaryActiveIcon",
      darkMode ? "#FFC541" : config.primary
    );
    root.style.setProperty("--Footer", darkMode ? "#20262D" : config.primary);
    root.style.setProperty("--InfoBanner", config.primary);
    root.style.setProperty("--primaryOnly", config.primary);
    root.style.setProperty("--secondaryOnly", config.Secondary);
    root.style.setProperty("--Price", darkMode ? "#FFC541" : config.primary);
  }, []);

  useEffect(() => {
    document.title = config.name;
    const root = document.documentElement;
    localStorage.setItem("vendor", config.key);

    root.style.setProperty("--primary", darkMode ? "#0D1116" : config.primary);
    root.style.setProperty(
      "--Secondary",
      darkMode ? "#20262D" : config.Secondary
    );
    root.style.setProperty("--textSecondary", config.textSecondary);
    root.style.setProperty("--DangerSecondary", config.DangerSecondary);
    root.style.setProperty("--CardColor", config.CardColor);
    root.style.setProperty("--DiscountPercentage", config.DiscountPercentage);
    root.style.setProperty("--Text", darkMode ? "#FFFFFF" : config.primary);
    //Dark theme
    root.style.setProperty("--Darkprimary", "#0D1116");
    root.style.setProperty("--DarkSecondary", "#20262D");
    root.style.setProperty(
      "--IconColor",
      darkMode ? "#FFFFFF" : config.primary
    );
    root.style.setProperty(
      "--DarkIconColor",
      darkMode ? "#0D1116" : config.primary
    );
    root.style.setProperty(
      "--DarkSecondaryActiveIcon",
      darkMode ? "#FFC541" : config.primary
    );
    root.style.setProperty("--Container", darkMode ? "#0D1116" : "#FFFFFF");
    root.style.setProperty("--Card", darkMode ? "#20262D" : "#F7FAFC");
    root.style.setProperty("--darkModal", darkMode ? "#21262D" : "#F7FAFC");
    root.style.setProperty("--CardText", darkMode ? "#FFFFFF" : "#000000");
    root.style.setProperty(
      "--DarkSecondaryCardText",
      darkMode ? "#7E8A98" : "#7E8A98"
    );
    root.style.setProperty("--Footer", darkMode ? "#20262D" : config.primary);
    root.style.setProperty("--InfoBanner", config.primary);
    root.style.setProperty("--primaryOnly", config.primary);
    root.style.setProperty("--secondaryOnly", config.Secondary);
    root.style.setProperty("--Price", darkMode ? "#FFC541" : config.primary);
  }, [darkMode]);

  useFavicon(footerLogo ? footerLogo : logo);

  return (
    <>
      <Router>
        {/* <Route path="/" exact component={LandingPage} /> */}
        <ChatContext.Provider
          value={{
            chatUs,
            setChatUs,
            notificationCount,
            setNotificationCount,
            wishCount,
            setWishCount,
            cartCount,
            setCartCount,
            prodChat,
            setProdChat,
            topHeader,
            setTopHeader,
            setLoginModal,
            LoginModal,
            setRedirectUser,
            redirectUser,
            couponsProducts,
            setCouponsProducts,
            couponDiscountValue,
            setCouponDiscountValue,
            couponDiscountPercentage,
            setCouponDiscountPercentage,
            mapLocation,
            setRedirectOutletName,
            redirectOutletName,
            redirectChainID,
            setRedirectChainID,
            setUserData,
            userData,
            setFeaturedProductId,
            featuredProductId,
            darkMode,
            setDarkMode,
            regularProduct,
            setRegularProduct,
          }}>
          <Router>
            <Header />
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
            <Footer />
          </Router>
        </ChatContext.Provider>
      </Router>
    </>
  );
}
const useFavicon = (href) => {
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [href]);
};
