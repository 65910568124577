import React from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";

import noimg from "../images/Bloom Icon (Filled) 1 (1).png";

export default function SkeletionLoading(props) {
  return (
    <>
      <Card
        className="filterSkel"
        style={{
          margin: "20px",
          width: "270px",
          height: "357px",
          fontFamily: "Oswald, sans-serif",
        }}>
        {!props.discount ? null : (
          <p
            style={{
              backgroundColor: props.discount > 35 ? "#FF6A6A" : "#2151ED",
              marginLeft: "10px",
              fontFamily: "Oswald, sans-serif",
              marginTop: "10px",
              height: "19px",
              color: "white",
              fontSize: "12px",
              width: "38px",
              textAlign: "center",
              borderRadius: "6px",
            }}>
            {props.discount}%
          </p>
        )}

        {!props.img ? (
          <Card.Img
            variant="bottom"
            style={{
              height: "88px",
              weight: "95px",
              marginTop: "42px",
              width: "95px",
              alignSelf: "center",
            }}
            src={noimg}
          />
        ) : (
          <Card.Img className="cardImg" src={props.img} />
        )}

        <Card.Body
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}>
          <div
            className="lower-card"
            style={{ width: "100%", display: "block" }}>
            <div style={{ textAlign: "start" }}>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            </div>

            <div>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={12} style={{ borderRadius: "10px" }} />
              </Placeholder>
            </div>

            <div style={{ textAlign: "start" }}>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            </div>

            <div style={{ marginTop: "38px" }}>
              <Placeholder.Button
                className="skelbutton"
                xs={4}
                aria-hidden="true"
                style={{ height: "10px", width: "118px" }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
