import { camelCase, startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Input from "react-phone-number-input/input";
//import item from "../../images/item.svg";
import url from "../../config/axios";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import config from "../../config";

function OrderDetails() {
  const params = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const [order, setOrder] = useState([]);
  const [products, setProducts] = useState([]);
  let orderid = params.orderid;
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const token = JSON.parse(localStorage.getItem("Etoken"));

  const history = useHistory();

  // console.log(order);

  useEffect(() => {
    getOrder(orderid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrder = (id) => {
    url
      .get(`/v1/user-orders-users/order?_id=${id}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then((res) => {
        setOrder(res.data.data.order);
        setProducts(res.data.data.order.lineItems);
        console.log(res.data.data.order);
      })
      .catch((error) => {});
  };

  const redirect = (link) => {
    history.push("/product/" + link);
    window.location.reload(false);
  };

  let curr = order[0]?.products[0]?.selectedVariantData?.pricingInfo?.currency
    ? order[0]?.products[0]?.selectedVariantData?.pricingInfo?.currency ===
      "USD"
      ? " $ "
      : order[0]?.products[0]?.selectedVariantData.pricingInfo?.currency + " $ "
    : " $ ";

  return (
    <>
      <div className="order-details">
        <div className="d-flex justify-content-between phoneOrderD">
          <section>
            <div className="details-card h-100">
              <div className="order-upper ">
                <div>
                  <span className="order-label">Order ID:</span>
                  <span className="order-id ">
                    {" "}
                    {params.orderid.toUpperCase()}
                  </span>
                  <div>
                    <span className="order-label">Placed On:</span>
                    <span className="order-labelValue">
                      {" "}
                      {moment(order?.createdAt).format("LLLL")}
                    </span>
                  </div>
                  <span>
                    <div>
                      <span className="order-label"> Outlet Address :</span>

                      <span className="order-labelValue">
                        {" "}
                        {order?.outletInfo?.address}
                      </span>
                    </div>
                  </span>
                </div>
              </div>

              <hr />
              {products.map((o, i) => (
                <>
                  <div
                    className="order-item w-100 d-flex justify-content-between  "
                    key={i}>
                    {!o.productInfo.rootProduct.image ? (
                      <img
                        className="cursor orderDnoImg"
                        onClick={() => {
                          redirect(o.productID);
                        }}
                        src={noimg}
                        alt=""
                      />
                    ) : (
                      <img
                        className="cursor"
                        onClick={() => {
                          redirect(o.productID);
                        }}
                        src={o.productInfo.rootProduct.image}
                        alt=""
                      />
                    )}

                    <div
                      className="row"
                      style={{ marginLeft: "20px", width: "100%" }}>
                      <div className="col-8 mobile-orderDetial-pName">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          <span
                            className="cursor orderDetials-prodName"
                            onClick={() => {
                              redirect(o.productID);
                            }}>
                            {startCase(
                              camelCase(o.productInfo.rootProduct.name)
                            )}
                          </span>
                          {o.productInfo.variant?.weight ? (
                            <span
                              className="cartVarient"
                              style={{ color: "#121212" }}>
                              {o.productInfo.variant?.weight}{" "}
                              {o.productInfo.variant?.uom}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col">
                        <span className="orderDetials-prodPrice ">
                          {o.purchaseQuantity} x{" "}
                          {o.selectedVariantData?.pricingInfo?.currency
                            ? o.selectedVariantData?.pricingInfo?.currency ===
                              "USD"
                              ? "$"
                              : o.selectedVariantData?.pricingInfo?.currency +
                                "$"
                            : "$"}
                          {" " +
                            (o?.addedProduct?.finalPricingInfo
                              ?.finalApplicableTotalPrice
                              ? o?.productInfo?.variant?.price +
                                o?.addedProduct?.finalPricingInfo
                                  ?.finalApplicableTotalPrice
                              : o?.productInfo?.variant?.price
                            )?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className=" mobile-h"
                    style={{ display: "flex", justifyContent: "flex-end" }}>
                    <hr className="w-75 mobile-order-hr " />
                  </div>
                </>
              ))}

              <div>
                {order?.appliedDiscounts?.couponDiscount?.discountApplied ? (
                  <>
                    <div className="order-price w-100 d-flex justify-content-end">
                      <div
                        className="d-flex  justify-content-between pe-1"
                        style={{ width: "76%" }}>
                        <span className="text-end bill-title-discount">
                          Coupon Discount
                        </span>
                        <span className="bill-title-discount">
                          {"- " +
                            curr +
                            parseFloat(
                              order?.appliedDiscounts?.couponDiscount
                                ?.discountApplied
                            )?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}

                <div style={{ marginTop: "20px" }}>
                  {order?.appliedDiscounts?.loyaltyDiscount?.discountApplied ? (
                    <>
                      <div className="order-price w-100 d-flex justify-content-end">
                        <div
                          className="d-flex  justify-content-between pe-1"
                          style={{ width: "76%" }}>
                          <span className="text-end bill-title-discount">
                            Loyalty Points
                          </span>
                          <span className="bill-title-discount">
                            {"- " +
                              curr +
                              parseFloat(
                                order?.appliedDiscounts?.loyaltyDiscount
                                  ?.discountApplied
                              )?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {order?.appliedDiscounts?.loyaltyDiscount?.discountApplied ||
                order?.appliedDiscounts?.couponDiscount?.discountApplied ? (
                  <div className="d-flex justify-content-end mobile-h">
                    <hr className="w-75 mobile-order-hr" />
                  </div>
                ) : null}
              </div>
              <div className="order-price w-100 d-flex justify-content-end">
                <div
                  className="d-flex  justify-content-between pe-1"
                  style={{ width: "76%" }}>
                  <span className="text-end order-label">
                    Subtotal ({order?.lineItems?.length}{" "}
                    {order?.lineItems?.length < 1 ? "items" : "item"} )
                  </span>
                  <span className="order-labelValue">
                    {order[0]?.products[0]?.selectedVariantData?.pricingInfo
                      ?.currency
                      ? order[0]?.products[0]?.selectedVariantData?.pricingInfo
                          ?.currency === "USD"
                        ? "$"
                        : order[0]?.products[0]?.selectedVariantData
                            ?.pricingInfo?.currency + "$"
                      : "$"}{" "}
                    {order?.finalSubTotal?.toFixed(2)}
                  </span>
                </div>
              </div>

              {order?.chargesBreakDownHierarchy?.map((t, i) => {
                return t?.chargedAmount < 0 ? null : (
                  <div
                    className="order-price w-100 d-flex justify-content-end"
                    style={{ marginTop: "20px" }}>
                    {t?.chargeDisplayName !== "Total" ? (
                      <div
                        className="d-flex  justify-content-between pe-1"
                        style={{ width: "76%" }}>
                        <span className="text-end order-label">
                          {t?.chargeDisplayName}
                        </span>
                        <span className="order-labelValue">
                          {curr + parseFloat(t?.chargedAmount)?.toFixed(2)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="d-flex justify-content-end mobile-h">
                <hr className="w-75 mobile-order-hr" />
              </div>

              <div className="order-price w-100 d-flex justify-content-end">
                <div
                  className="d-flex  justify-content-between pe-1"
                  style={{ width: "76%" }}>
                  {order?.chargesBreakDownHierarchy?.map((t, i) => {
                    return (
                      <>
                        {t?.chargeDisplayName === "Total" ? (
                          <>
                            <span className="orderDetials-prodName">
                              {t?.chargeDisplayName}
                            </span>
                            <span className="orderDetials-prodPrice">
                              {curr + parseFloat(t?.chargedAmount)?.toFixed(2)}
                            </span>
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          <section
            className="phone-right-orderDetials"
            style={{ wordWrap: "break-word", marginLeft: "20px" }}>
            <div className="add-card " style={{ wordWrap: "break-word" }}>
              <span className="orderDetials-prodName">Billing Address</span>
              <hr />

              <span className="order-label">Outlet name: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order?.outletInfo?.adminName}
              </span>
              <br />
              <span className="order-label">Customer Name: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order?.userInfo?.name}
              </span>
              <br />
              <span className="order-label">Customer Email: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order?.userInfo?.email}
              </span>
              <br />
              <span className="order-label" style={{ marginTop: "25px" }}>
                Phone Number:{" "}
              </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                <Input
                  className="add-card-labelValue"
                  style={{
                    width: "100%",
                    padding: "0px",
                    border: "none",
                    fontWeight: "500",
                    color: "#00000",
                    fontSize: "18px",
                  }}
                  disabled
                  value={user[0]?.mobileNo ? user[0]?.mobileNo : "N/A"}
                />
                {}
              </span>
              <br />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
