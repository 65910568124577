import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import url from "../../config/axios";
import config from "../../config";

import "./banner.css";

function Banner() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState([]);
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  // console.log(isLoaded);

  useEffect(() => {
    url
      .get(
        `/v1/consumer-banners-users/all?consumerID=${config.consumerId}&outletChainID=${chainID}`,
        {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
          },
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setBanner(res.data.data.banners);
          res.data.data.banners.map((e) => {
            if (!e?.image) setIsLoaded(true);
          });
        } else {
        }
      });
  }, [chainID]);

  // console.log(banner);
  return (
    <>
      <Carousel
        style={{ width: "100%", borderRadius: "5px", zIndex: "1" }}
        variant="dark"
        controls={false}
        className="mx-auto "
        indicators={false}>
        {banner.map((e) => {
          return (
            <Carousel.Item style={{ height: "628px" }}>
              {e?.targetTopic ? (
                <>
                  <Link
                    to={
                      e?.targetTopic === "PRODUCTS"
                        ? "/product/" + e?.targetInfo?.productID
                        : e.targetTopic === "CATEGORIES"
                        ? "/products/category/" + e?.targetInfo?.categoryID
                        : e.targetTopic === "MANUFACTURERS"
                        ? "/products/manufacturers/" + e?.targetInfo?.brandID
                        : e.targetTopic === "DEALS"
                        ? "/deals/" + e?.targetInfo?.slug
                        : null
                    }
                    style={{ textDecoration: "none", height: "15px" }}>
                    <img
                      className="d-block w-100"
                      style={{
                        display: !isLoaded ? "none" : "flex",
                        position: " absolute",
                      }}
                      src={e.image}
                      alt=""
                      onLoad={() => {
                        setIsLoaded(true);
                      }}
                    />
                  </Link>
                  {!isLoaded ? (
                    <div className="spinner">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <img
                    className="d-block w-100"
                    style={{
                      display: !isLoaded ? "none" : "flex",
                      position: " absolute",
                    }}
                    src={e.image}
                    alt=""
                    onLoad={() => {
                      setIsLoaded(true);
                    }}
                  />
                  {!isLoaded ? (
                    <div className="spinner">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : null}
                </>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}

// style={{ backgroundImage: `url(${e.image})`, objectFit: "contain" }}

export default Banner;
