import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import carticon from "../../Icons/cartIcon.svg";
import greenPlaceholder from "../../images/Green_placeholder.png";
import wishicon from "../../Icons/wishlistIcon.svg";
import Spinner from "react-bootstrap/Spinner";
import ProductCards from "../Home/ProductCards";

function ProductPage(props) {
  let history = useHistory();

  const {
    setChatUs,
    setWishCount,
    cartCount,
    setCartCount,
    setProdChat,
    setLoginModal,
    redirectUser,
    redirectChainID,
    userData,
    darkMode,
    regularProduct,
  } = useContext(ChatContext);

  const [productDetails, setProductDetails] = useState();
  const [getCartProduction, setGetCartProduction] = useState([]);
  const [count, setCount] = useState(1);
  const [regularProductDiscount, setRegularProductDiscount] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [selectedVariantData, setSelectedVariantData] = useState({});
  const [valCount, setValCount] = useState(false);
  const [loader, setLoader] = useState(false);
  const [skel, setskel] = useState(true);
  const [cartLoader, setCartLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [foundProduct, setFoundProduct] = useState([]);

  const uid = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem(
    "user",
    JSON.stringify(redirectUser.length !== 0 ? redirectUser : uid)
  );
  const chainID = redirectChainID
    ? redirectChainID
    : JSON.parse(localStorage.getItem("outletChainID"));

  const token = JSON.parse(localStorage.getItem("Etoken"));
  // const regularProduct = JSON.parse(localStorage.getItem("regularProduct"));

  //  console.log(regularProduct);
  useEffect(() => {
    if (props?.productpage) {
      const matchedProducts =
        regularProduct?.dealInfo?.REGULAR?.chosenProducts?.filter(
          (e) => e === props?.productpage
        );
      if (matchedProducts?.length > 0) {
        setRegularProductDiscount([regularProduct?.dealInfo?.REGULAR?.getOff]);
        console.log("true");
      } else {
        console.log(matchedProducts);
        console.log(props?.productpage);
        console.log("else");
        setRegularProductDiscount([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productpage]);

  useEffect(() => {
    if (userData) {
      GetCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    GetProductDetails();
    getWishItems();
    GetCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.productpage]);

  if (uid === null) {
    localStorage.removeItem("user");
  }
  let loggedIn =
    localStorage.getItem("user") && uid?.length !== 0 ? true : false;

  const checkcount = (value) => {
    setCartCount(value);
  };

  const GetProductDetails = () => {
    url
      .get(`/inventory/id?id=${props?.productpage}&outletChainID=${chainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          // console.log(res.data.data);
          setskel(false);
          // setIsLoaded(true)
          setProductDetails(res.data.data);
          setProdChat(res.data.data);
          setSelectedVariantData(res.data.data.variants[0]);
        } else {
        }
      });
  };

  const GetCart = () => {
    if (!loggedIn) return;
    url
      .get(`/v1/user-cart/get-plain?outletChainID=${chainID}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })

      .then(async (res) => {
        //  console.log(res.data.data);
        if (res.data.Message === "Success") {
          setGetCartProduction(res.data.data);
          checkcount(res?.data?.data?.cart?.items?.length);
          setCartLoader(true);
          //console.log(res.data.data);
        } else {
          setCartLoader(false);
        }
      });
  };

  let selectedVarientQuantity = selectedVariantData?.quantity
    ? selectedVariantData?.measured_by == "UNIT"
      ? selectedVariantData?.quantity
      : selectedVariantData?.weight
    : productDetails?.quantity;

  // console.log(selectedVarientQuantity);

  const tosterCart = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "Added to Cart",
    });
  };

  const tosterWishlist = () => {
    if (loggedIn) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Added to Wishlist",
      });
    }
  };

  const addToCart = async () => {
    if (!loggedIn) return setLoginModal(true);

    if (productDetails?.quantity <= 0)
      return Swal.fire("Missing ", "Out of stock", "warning");
    selectedVariantData.price = regularProductDiscount[0]
      ? selectedVariantData.price -
        (selectedVariantData.price * regularProductDiscount[0]?.numericAmount) /
          100
      : selectedVariantData.price;

    let obj = {
      variant: selectedVariantData,
      productID: productDetails.product.productID,
      targetDealID: regularProduct?._id,
      outletChainID: chainID,
      purchaseQuantity: count,
    };

    //return console.log(obj);

    let found = false;
    console.log(getCartProduction);
    getCartProduction?.cart?.items?.filter((k) => {
      // console.log(k);

      if (k?.productInfo?.variant?.weight) {
        if (
          k?.productInfo?.variant?.productID === obj.productID &&
          k?.productInfo?.variant?.weight === obj?.variant?.weight
        ) {
          found = true;
          GetCart();
        } else {
        }
      } else {
        if (k?.productInfo?.variant?.productID === obj.productID) {
          found = true;
          GetCart();
        } else {
        }
      }
    });

    if (!found) {
      selectedVariantData.price = regularProductDiscount[0]
        ? selectedVariantData.price -
          (selectedVariantData.price *
            regularProductDiscount[0]?.numericAmount) /
            100
        : selectedVariantData.price;
      url
        .put(
          `/v1/user-cart/update-item`,
          {
            variant: selectedVariantData,
            productID: obj.productID,
            targetDealID: regularProduct?._id,
            outletChainID: chainID,
            purchaseQuantity: count,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          //  console.log(res);
          if (res.data.Message === "Success") {
            //  console.log(found + "new cart created");
            setCartLoader(false);
            // AddToCart(obj);
            setCount(1);
            tosterCart();
            await GetCart();
          } else {
            setCartLoader(true);
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status === 401) {
            return Swal.fire(
              "Oops! ",
              "Session Expired! Please sign in again.",
              "warning"
            ).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("user");
                history.push("/");
              }
            });
          }
        });
    } else {
      //let ft = [];
      // getCartProduction.map((k, i) => {
      // k.products.map((k1, i) => {
      //   if (k1.productID === obj.product.productID) {
      //     //k1.product.quantity = k1.product.quantity + count;
      //     k1.product.purchaseQuantity = k1.product.purchaseQuantity + count;
      //     ft.push(k);
      //     //console.log(k);
      //   }
      // });
      //return console.log(ft[0]);
      let cartCount = 0;
      getCartProduction?.cart?.items.map((k, i) => {
        if (k.productID === obj.productID) {
          cartCount = count + k.purchaseQuantity;
        }
      });
      selectedVariantData.price = regularProductDiscount[0]
        ? selectedVariantData.price -
          (selectedVariantData.price *
            regularProductDiscount[0]?.numericAmount) /
            100
        : selectedVariantData.price;
      return url
        .put(
          `/v1/user-cart/update-item`,
          {
            variant: selectedVariantData,
            productID: obj.productID,
            targetDealID: regularProduct?._id,
            outletChainID: chainID,
            purchaseQuantity: cartCount,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          // console.log(res);
          if (res.data.Message === "Success") {
            setCartLoader(false);
            await GetCart();
            // console.log(res.data.toSend.data);
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "Cart updated successfully",
            });
          } else {
            setCartLoader(true);
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status === 401) {
            return Swal.fire(
              "Oops! ",
              "Session Expired! Please sign in again.",
              "warning"
            ).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("user");
                history.push("/");
              }
            });
          }
        });
      // });
    }
    // console.log(id);
  };

  const chkCart = () => {
    if (!uid) return setLoginModal(true);
    if (productDetails?.quantity === 0)
      Swal.fire("Missing ", "Out of stock", "warning");
  };

  const chkWish = () => {
    if (!uid) return setLoginModal(true);
    if (productDetails?.quantity === 0)
      Swal.fire("Missing ", "Out of stock", "warning");
  };

  const getWishItems = () => {
    if (!uid) return;
    url
      .get(`/v1/user-wishlists/get-all-items`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })
      .then((res) => {
        if (res.data.Message === "Success") {
          setWishList(res?.data?.data.wishLists);
          setWishCount(res?.data?.data?.wishLists?.length);
          setLoader(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const addToWishlist = () => {
    if (!uid) return setLoginModal(true);
    console.log(wishList);
    let found = false;
    wishList?.map((e) => {
      //console.log(e?.productInfo?.variants[e.product.productID]?.weight);
      if (
        e?.productInfo?.variants[e?.productInfo?.product?.productID]?.weight
      ) {
        if (
          e?.productInfo?.product?.productID === productDetails.productID &&
          e?.productInfo?.variants[e?.productInfo?.product?.productID]
            ?.weight === selectedVariantData.weight
        ) {
          // e.productInfo.variants[e.productInfo.product.productID].quantity +=
          //   count;
          found = true;
          tosterWishlist();
        } else {
          // existWishlist();
        }
      } else {
        if (e.product.productID === productDetails.productID) {
          e.product.quantity += count;
          found = true;
          tosterWishlist();
        } else {
          // existWishlist();
        }
      }
      return e;
    });
    if (found) {
      if (loggedIn) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: "Wishlist already exists",
        });
      }
    } else {
      selectedVariantData.price = regularProductDiscount[0]
        ? selectedVariantData.price -
          (selectedVariantData.price *
            regularProductDiscount[0]?.numericAmount) /
            100
        : selectedVariantData.price;

      //selectedVariantData.tempProductID = regularProduct?._id;

      let WBody = {
        productID: productDetails.product.productID,
        variant: selectedVariantData,
        targetDealID: regularProduct?._id,
      };

      // return console.log(WBody);
      url
        .put(`/v1/user-wishlists/add-item`, WBody, {
          headers: {
            outletchainid: chainID,
            consumerid: config.consumerId,
            auth: token,
          },
        })

        .then(async (res) => {
          getWishItems();
          setLoader(true);
          tosterWishlist();
        })
        .catch((e) => console.log(e));
    }
  };

  const CountHandle = (e) => {
    // const value = e.replace(/\D/g, "");
    const intRegex = /^[0-9]*$/;
    const regex = /^[0-9]*[.]?[0-9]*$/;

    if (selectedVariantData?.measured_by !== "UNIT" && regex.test(e)) {
      if (e <= selectedVarientQuantity) {
        const validated = e.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (validated) {
          setCount(e);
          setValCount(false);
        }
      } else {
        setValCount(true);
      }
    } else {
      if (intRegex.test(e)) {
        if (e <= selectedVarientQuantity) {
          setCount(parseInt(e));
          setValCount(false);
        } else {
          setValCount(true);
        }
      }
    }
  };
  //  console.log(productDetails);

  return (
    <div className="single-product">
      <>
        <div className="prd-details">
          <div className="prd-image">
            {productDetails?.product?.image ? (
              <img
                src={
                  selectedVariantData?.image
                    ? selectedVariantData?.image
                    : productDetails?.product?.image
                }
                style={{ display: !isLoaded ? "none" : "flex" }}
                alt=""
                onLoad={() => {
                  setIsLoaded(true);
                }}
              />
            ) : (
              <img
                src={noimg}
                className="pNoImg"
                alt=""
                // style={{ display: !isLoaded ? "none" : "flex" }}
              />
            )}
            {!isLoaded && productDetails?.product?.image ? (
              <Spinner
                style={{ width: "100px", height: "100px", marginTop: "100px" }}
                animation="grow"
                variant="primary"
                className="colorBackground"
              />
            ) : null}
          </div>
          <div className="prd-desc">
            {!skel ? (
              <span>
                {selectedVariantData?.name
                  ? selectedVariantData?.name
                  : productDetails?.product?.name}
              </span>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
            {!skel ? (
              <span className="potency">
                {/* {productDetails?.thc ? */}
                <p>
                  {/* <img src={downArrow} alt="down" /> */}
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="PrimaryOnlySvg"
                      d="M19.6902 0.639884C20.46 0.639884 20.9411 1.47322 20.5562 2.13988L11.6484 17.5687C11.2635 18.2354 10.3012 18.2354 9.91633 17.5687L1.00849 2.13988C0.623593 1.47322 1.10472 0.639884 1.87452 0.639884L19.6902 0.639884Z"
                      fill="#1F6355"
                    />
                  </svg>
                  &nbsp;
                  {productDetails?.product?.thc
                    ? productDetails?.product?.thc + "%"
                    : "N/A"}{" "}
                  THC
                </p>

                <p>
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="PrimaryOnlySvg"
                      d="M1.91333 18.2927C1.14353 18.2927 0.662403 17.4594 1.0473 16.7927L9.95514 1.36391C10.34 0.697245 11.3023 0.697245 11.6872 1.36391L20.595 16.7927C20.9799 17.4594 20.4988 18.2927 19.729 18.2927L1.91333 18.2927Z"
                      fill="#1F6355"
                    />
                  </svg>
                  &nbsp;
                  {productDetails?.product?.cbd
                    ? productDetails?.product?.cbd + "%"
                    : "N/A"}{" "}
                  CBD
                </p>
                {/* :null} */}
              </span>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={3} style={{ borderRadius: "10px" }} />
                <Placeholder xs={3} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
            {!skel ? (
              productDetails?.product?.brandID ? (
                <span>
                  <Link
                    to={
                      "/products/manufacturers/" +
                      productDetails?.product?.brandID
                    }
                    className="Sp-Brand"
                    style={{ textDecoration: "none" }}>
                    Brand:{" "}
                    <mark
                      className="Sp-BrandName"
                      style={{ background: "none" }}>
                      {productDetails?.product?.brandName
                        ? productDetails?.product?.brandName
                        : "N/A"}
                    </mark>
                  </Link>
                </span>
              ) : (
                <span>
                  <span className="pBrand secondColor">Brand:</span>{" "}
                  <mark style={{ background: "none" }}>
                    {productDetails?.product?.brandName
                      ? productDetails?.product?.brandName
                      : "N/A"}
                  </mark>
                </span>
              )
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={4} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}

            <div className="row varients" style={{ marginTop: "5%" }}>
              <div className="col">
                <div
                  className="count countProdPage"
                  style={{ display: "flex", width: "53%", height: "32px" }}>
                  {count <= 1 || !count ? (
                    <button
                      className="minus"
                      disabled
                      style={{
                        background: "gray",
                        width: "22px",
                        height: "23px",
                      }}
                      onClick={() => {
                        if (count - 1 > 0) setCount(count - 1);
                      }}>
                      {" "}
                      <span style={{ color: "black", fontSize: "27px" }}>
                        {" "}
                        -
                      </span>
                    </button>
                  ) : (
                    <button
                      className="minus"
                      style={{ width: "22px", height: "23px" }}
                      onClick={() => {
                        if (count - 1 > 0) {
                          setCount(count - 1);
                          setValCount(false);
                        }
                      }}>
                      {" "}
                      <span style={{ color: "black", fontSize: "27px" }}>
                        {" "}
                        -{" "}
                      </span>
                    </button>
                  )}

                  <input
                    type="tel"
                    value={count && selectedVarientQuantity > 0 ? count : 0}
                    onChange={(e) => {
                      CountHandle(e.target.value);
                    }}
                  />

                  <button
                    className="plus"
                    onClick={() => {
                      if (count + 1 > 0) {
                        setCount(parseInt(count) + 1);
                      }
                      if (count >= productDetails?.quantity) {
                        setValCount(true);
                        setCount(parseInt(productDetails?.quantity));
                      }
                      // else setValCount(false)
                    }}>
                    {" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "27px",
                        fontWeight: "300",
                      }}>
                      {" "}
                      +{" "}
                    </span>
                  </button>
                </div>{" "}
                {valCount ? (
                  <span style={{ fontSize: "15px", color: "rgb(255, 99, 99)" }}>
                    Available Stock Qty : {selectedVarientQuantity}
                    <br />
                  </span>
                ) : null}
                {!selectedVarientQuantity ? (
                  <span style={{ fontSize: "15px", color: "rgb(255, 99, 99)" }}>
                    {" "}
                    Out of Stock
                    <br />
                  </span>
                ) : null}
                <span
                  onClick={() => {
                    setChatUs(true);
                  }}
                  className="cursor"
                  style={{ marginTop: "0px", fontSize: "14px" }}>
                  Chat with us
                </span>
              </div>

              <div className="col">
                {productDetails?.variants?.length === 0 ||
                !productDetails?.variants ? (
                  regularProductDiscount ? (
                    <div className="prd-price" style={{ display: "block" }}>
                      {!skel ? (
                        <span style={{ width: "100%" }}>
                          ${" "}
                          {parseInt(
                            productDetails?.originalPrice -
                              (productDetails?.originalPrice *
                                productDetails?.discountPercentage) /
                                100
                          )?.toFixed(2)}{" "}
                        </span>
                      ) : (
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder
                            xs={4}
                            style={{ borderRadius: "10px" }}
                          />
                        </Placeholder>
                      )}
                      {!skel ? (
                        <>
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginLeft: "10px",
                              marginRight: "5px",
                            }}>
                            $ {productDetails?.originalPrice?.toFixed(2)}{" "}
                          </span>
                          <span style={{ color: "blue", fontSize: "12px" }}>
                            {productDetails?.discountPercentage}% OFF
                          </span>
                        </>
                      ) : (
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder
                            xs={4}
                            style={{ borderRadius: "10px" }}
                          />
                        </Placeholder>
                      )}
                    </div>
                  ) : (
                    <div className="prd-price">
                      {productDetails?.originalPrice ? (
                        <span
                          style={{
                            lineHeight: "0px",
                            width: "100%",
                          }}>
                          $ {productDetails?.originalPrice?.toFixed(2)}
                        </span>
                      ) : null}
                    </div>
                  )
                ) : (
                  <div style={{ cursor: "pointer", display: "flex" }}>
                    {!skel ? (
                      <div className="row">
                        {productDetails?.variants?.map((item) => {
                          return (
                            <div
                              // varientShadow
                              className=""
                              style={{
                                borderWidth: 5,
                                marginBottom: " 5%",
                                border:
                                  selectedVariantData.weight === item.weight
                                    ? darkMode
                                      ? "3px solid white"
                                      : " 3px solid black"
                                    : darkMode
                                    ? "3px solid #7E8A98"
                                    : "3px solid gray",
                                boxSizing: "borderBox",
                                borderRadius: 12,
                                justifyContent: "center",
                                alignItems: "center",
                                width: regularProductDiscount
                                  ? "120px"
                                  : "100px",
                                height: regularProductDiscount
                                  ? "120px"
                                  : "100px",
                                marginRight: "10px",
                                padding: "0px",
                                display: "flex",
                              }}
                              onClick={() => setSelectedVariantData(item)}>
                              <div className="col ">
                                <div
                                  style={{
                                    marginTop: "0px",
                                    textAlign: "center",
                                    color: darkMode ? "#FFFFFF" : "#121212",
                                  }}>
                                  {regularProductDiscount?.length !== 0 ? (
                                    <span
                                      style={{
                                        lineHeight: "0px",
                                        color:
                                          selectedVariantData?.weight ===
                                          item?.weight
                                            ? darkMode
                                              ? "#FFFFFF"
                                              : "#121212"
                                            : darkMode
                                            ? "#7E8A98"
                                            : "grey",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}>
                                      {selectedVariantData?.pricingInfo
                                        ?.currency
                                        ? selectedVariantData?.pricingInfo
                                            ?.currency === "USD"
                                          ? "$"
                                          : selectedVariantData?.pricingInfo
                                              ?.currency + "$"
                                        : "$ "}{" "}
                                      {(
                                        item?.price -
                                        (item?.price *
                                          regularProductDiscount[0]
                                            ?.numericAmount) /
                                          100
                                      )?.toFixed(2)}
                                      <br />
                                    </span>
                                  ) : null}

                                  <span
                                    style={{
                                      color:
                                        selectedVariantData?.weight ===
                                        item?.weight
                                          ? darkMode
                                            ? "#FFFFFF"
                                            : "#121212"
                                          : darkMode
                                          ? "#7E8A98"
                                          : "grey",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      textDecoration:
                                        regularProductDiscount?.length !== 0
                                          ? "line-through"
                                          : "none",
                                    }}>
                                    {selectedVariantData?.pricingInfo?.currency
                                      ? selectedVariantData?.pricingInfo
                                          ?.currency === "USD"
                                        ? "$"
                                        : selectedVariantData?.pricingInfo
                                            ?.currency + "$"
                                      : "$ "}{" "}
                                    {parseFloat(item?.price)?.toFixed(2)}{" "}
                                  </span>

                                  {regularProductDiscount?.length !== 0 ? (
                                    <span
                                      className="Sp-varientPercent"
                                      style={{
                                        fontSize: "13px",
                                      }}>
                                      <br />
                                      {regularProductDiscount[0]?.type ===
                                      "PERCENTAGE"
                                        ? regularProductDiscount[0]
                                            ?.numericAmount +
                                          "%" +
                                          "OFF"
                                        : null}
                                    </span>
                                  ) : null}

                                  {" / "}
                                  {selectedVariantData?.measured_by === "UNIT"
                                    ? "item"
                                    : item.uom}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={3} style={{ borderRadius: "10px" }} />
                        <Placeholder
                          xs={3}
                          style={{ borderRadius: "10px", height: "32px" }}
                        />
                      </Placeholder>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row Sprod_btn">
              <span style={{ display: "flex", marginTop: "7%" }}>
                {count === 0 || !count || !selectedVarientQuantity ? (
                  <>
                    {regularProductDiscount?.length !== 0 ? null : (
                      <div className="col">
                        <button
                          style={{ background: "rgba(186, 186, 186, 186)" }}
                          onClick={() => {
                            chkWish();
                          }}>
                          <img
                            style={{
                              paddingRight: "15px",
                              paddingBottom: "6px",
                              background: "rgba(186, 186, 186, 0.53)",
                            }}
                            src={wishicon}
                            alt="wicon"
                          />
                          Add to Wishlist
                        </button>
                      </div>
                    )}
                    <div className="col">
                      <button
                        style={{
                          background: "rgba(186, 186, 186, 186)",
                          marginBottom: "20px",
                        }}
                        onClick={() => {
                          chkCart();
                        }}>
                        <img
                          style={{
                            paddingRight: "0px",
                            marginRight: "24px",
                            marginLeft: "-24px",
                            paddingBottom: "6px",
                          }}
                          src={carticon}
                          alt="cicon"
                        />
                        Add to Cart
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {regularProductDiscount?.length !== 0 ? null : (
                      <div className="col">
                        <button
                          disabled={loader}
                          className="wishlistbtn dangerBackground"
                          onClick={() => {
                            addToWishlist();
                            setLoader(false);
                          }}>
                          <img
                            style={{
                              paddingRight: "15px",
                              paddingBottom: "6px",
                            }}
                            src={wishicon}
                            alt="wicon"
                          />
                          Add to Wishlist
                        </button>
                      </div>
                    )}
                    <div className="col">
                      <button
                        onClick={() => {
                          addToCart();
                          checkcount(cartCount);
                        }}>
                        <img
                          style={{
                            paddingRight: "0px",
                            marginRight: "24px",
                            marginLeft: "-24px",
                            paddingBottom: "6px",
                          }}
                          src={carticon}
                          alt="cicon"
                        />
                        Add to Cart
                      </button>
                    </div>
                  </>
                )}
              </span>
            </div>
            <hr />
            <div className="desc">
              <span>Description</span>
              <span>
                {productDetails?.product?.shortDescription
                  ? productDetails?.product?.shortDescription
                  : "N/A"}
              </span>
            </div>
            <hr />
            <span>Effects</span>
            {!productDetails?.product?.effects ? (
              <div style={{ color: "#a6a6a6", fontSize: "18px" }}>
                <br /> N/A
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                {productDetails?.product?.effects?.map((e) => {
                  return (
                    <div
                      className="effect"
                      style={{ display: e.length === 0 ? "none" : "flex" }}>
                      {" "}
                      <p> {e} </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </>

      <div
        className="container singleProductPageFeaturedContainer-phone"
        style={{ margin: "85px 85px" }}>
        <ProductCards title="Featured products" />
      </div>
    </div>
  );
}
export default ProductPage;
