import { camelCase, startCase } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import url from "../../config/axios";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import { ChatContext } from "../../Contexts/ChatContext";
import config from "../../config";

export default function FWishlist({ itemt, deleteWishlist, getWishItems }) {
  // console.log(itemt);
  const { setCartCount } = useContext(ChatContext);
  let itemx = itemt?.map((e) => {
    return { ...e, inCart: false };
  });

  const [items, setItems] = useState([]);
  const [getCartProduction, setGetCartProduction] = useState([]);

  const token = JSON.parse(localStorage.getItem("Etoken"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  let count = 1;
  let loggedIn = localStorage.getItem("user") ? true : false;
  const history = useHistory();

  useEffect(() => {
    if (!loggedIn) {
      history.push("/");
      window.location.reload(false);
    }
    setItems(itemx);
    GetCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetCart = () => {
    if (!loggedIn) return;
    url
      .get(`/v1/user-cart/get-plain?outletChainID=${chainID}`, {
        headers: {
          outletchainid: chainID,
          consumerid: config.consumerId,
          auth: token,
        },
      })

      .then(async (res) => {
        if (res.data.Message === "Success") {
          setGetCartProduction(res.data.data);
          setCartCount(res?.data?.data?.cart?.items?.length);
        } else {
        }
      });
  };

  const tosterCart = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "Added to Cart",
    });
  };

  const moveToCart = async (i) => {
    // console.log(itemx[i]?.product.product?.discountPrice);
    // console.log(items[i]);
    let obj = {
      productID: items[i].productID,
      variantID: items[i].variantID,
      variant: items[i]?.productInfo?.variants[items[i].productID],
      product: {
        productID: itemx[i].productID,
        name: itemx[i].productInfo.product.name,
        quantity: itemx[i].productInfo.quantity,
        brandName: itemx[i].productInfo.product.brandName,
        brandID: itemx[i].productInfo.product.brandID,
        image: itemx[i].productInfo.product.image,
        price: items[i]?.productInfo?.variants[items[i].productID]?.price
          ? items[i]?.productInfo?.variants[items[i].productID]?.price
          : itemx[i]?.productInfo?.originalPrice,
        invertory: itemx[i]?.productInfo.quantity,
        discountPrice: itemx[i]?.productInfo.product?.discountPrice
          ? itemx[i]?.productInfo.product?.discountPrice
          : null,
        purchaseQuantity: 1,
      },
    };

    //return console.log(obj);
    let found = false;
    getCartProduction?.cart?.items?.filter((k) => {
      // console.log(k);

      if (k?.productInfo?.variant?.weight) {
        if (
          k?.productInfo?.variant?.productID === obj.product.productID &&
          k?.productInfo?.variant?.weight === obj?.variant?.weight
        ) {
          found = true;
          GetCart();
        } else {
        }
      } else {
        if (k?.productInfo?.variant?.productID === obj.product.productID) {
          found = true;
          GetCart();
        } else {
        }
      }
    });

    if (!found) {
      // let ft = [];
      // getCartProduction.map((k, i) => {
      //   ft.push(...k.products, obj);
      // });
      //return console.log(obj);
      url
        .put(
          `/v1/user-wishlists/move-to-cart`,
          {
            productID: obj.productID,
            variantID: obj.variantID,
            outletChainID: chainID,
          },
          {
            headers: {
              outletchainid: chainID,
              consumerid: config.consumerId,
              auth: token,
            },
          }
        )
        .then(async (res) => {
          // console.log(res);
          if (res.data.Message === "Success") {
            getWishItems();
            //console.log(items[i].wishlistID);
            //  await deleteWishlist(items[i].variantID, items[i].productID);
            // AddToCart(obj);
            //setCount(1);
            tosterCart();
            GetCart();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        });
    } else {
      let ft = [];
      getCartProduction.map(async (k, i) => {
        k.products.filter((k1) => {
          //console.log(k1);
          // console.log(obj);
          if (k1?.variant?.weight) {
            if (
              k1.productID === obj?.variant.productID &&
              k1?.variant?.weight === obj?.variant?.weight
            ) {
              k1.product.quantity = k1.product.quantity + count;
              k1.product.purchaseQuantity = k1.product.purchaseQuantity + count;
              ft.push(k);
            } else {
            }
          } else {
            if (k1.productID === obj?.variant.productID) {
              k1.product.quantity = k1.product.quantity + count;
              k1.product.purchaseQuantity = k1.product.purchaseQuantity + count;
              ft.push(k);
            } else {
            }
          }
        });

        return url
          .put(
            `/v1/user-wishlists/move-to-cart`,
            {
              productID: obj.productID,
              variantID: obj.variantID,
              outletChainID: chainID,
            },
            {
              headers: {
                outletchainid: chainID,
                consumerid: config.consumerId,
                auth: token,
              },
            }
          )
          .then(async (res) => {
            // console.log(res);
            if (res.data.Message === "Success") {
              getWishItems();
              // await deleteWishlist(items[i].variantID, items[i].productID);
              // console.log(res.data.toSend.data);
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "warning",
                title: "Cart updated successfully",
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "warning",
                title: res.data.message,
              });
            }
          });
      });
    }
  };
  // console.log(itemt[0].variantID, itemt[0].productID);

  // console.log(
  //   itemt[0]?.productInfo?.variants[itemt[0]?.productInfo?.productID]
  // );

  return (
    <div
      className="container"
      style={{ marginBottom: "141px", paddingTop: "0px" }}>
      <div className="wishlist-list">
        {itemt?.map((itemCard, i) => {
          let foundprod = false;
          // console.log(itemCard.product.variants[0].price);
          return (
            <div className="wishlist-card" key={i}>
              <div className="upperwishlist">
                {/* <img style={{ cursor: "pointer" }} src={deleteicon} alt=""  /> */}

                <svg
                  onClick={() => {
                    deleteWishlist(itemCard.variantID, itemCard.productID);
                  }}
                  style={{ cursor: "pointer" }}
                  width="23"
                  height="21"
                  viewBox="0 0 23 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="svgColor strokeColor"
                    d="M11.5 21L10.9671 20.6456C10.5193 20.3477 0 13.2794 0 6.90557C0 2.37181 3.16832 0 6.29798 0C8.29864 0 10.1725 0.94935 11.4992 2.59001C12.8216 0.952214 14.6983 0 16.7023 0C19.8319 0 23 2.37207 23 6.90557C23 13.2794 12.4807 20.3477 12.0331 20.6456L11.5 21Z"
                    fill="#08754C"
                  />
                </svg>
              </div>
              {!itemCard?.productInfo?.product?.image ? (
                <img src={noimg} className="noWishimg" alt="" />
              ) : (
                <img
                  src={itemCard?.productInfo?.product?.image}
                  className="wishimg"
                  alt=""
                />
              )}
              <div className="lowerwishlist">
                <Link
                  to={"/product/" + itemCard?.productInfo?.productID}
                  style={{ textDecoration: "none", height: "40px" }}>
                  {itemCard?.productInfo?.product?.name?.length < 25 ? (
                    <span className="phone-WishName">
                      {startCase(
                        camelCase(
                          itemCard?.productInfo?.variants[itemCard?.variantID]
                            ?.name
                            ? itemCard?.productInfo?.variants[
                                itemCard?.variantID
                              ]?.name
                            : itemCard?.productInfo?.product?.name
                        )
                      )}
                    </span>
                  ) : (
                    <span className="phone-WishName">
                      {startCase(
                        camelCase(
                          itemCard?.productInfo?.product?.name
                        ).substring(0, 25)
                      )}
                      ...
                    </span>
                  )}
                </Link>
                <div className="prd-price w-100">
                  {itemCard?.productInfo?.product?.discountPrice ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <span>
                        {itemCard?.productInfo?.variants[itemCard?.variantID]
                          ?.pricingInfo?.currency
                          ? itemCard?.productInfo?.variants[itemCard?.variantID]
                              ?.pricingInfo?.currency === "USD"
                            ? "$ "
                            : itemCard?.productInfo?.variants[
                                itemCard?.variantID
                              ]?.pricingInfo?.currency + "$ "
                          : "$ "}
                        {parseFloat(
                          itemCard?.productInfo?.product?.discountPrice
                        ).toFixed(2)}
                      </span>
                      {/* // : <span>$ {itemCard?productInfo?.price}</span>} */}
                      <span
                        style={{
                          fontSize: "12px",
                          lineHeight: "21px",
                          fontWeight: "500",
                          height: "21px",
                          color: "gray",
                          textDecoration: "line-through",
                          marginLeft: "10px",
                        }}>
                        {" "}
                        ${" "}
                        {parseFloat(
                          itemCard?.productInfo?.product?.price
                        ).toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}>
                      {itemCard?.productInfo?.variants[itemCard?.variantID]
                        ?.pricingInfo?.currency
                        ? itemCard?.productInfo?.variants[itemCard?.variantID]
                            ?.pricingInfo?.currency === "USD"
                          ? "$ "
                          : itemCard?.productInfo?.variants[itemCard?.variantID]
                              ?.pricingInfo?.currency + "$ "
                        : "$ "}

                      {itemCard?.productInfo?.variants[itemCard?.variantID]
                        ?.price
                        ? parseFloat(
                            itemCard?.productInfo?.variants[itemCard?.variantID]
                              ?.price
                          ).toFixed(2)
                        : parseFloat(
                            itemCard?.productInfo?.product?.price
                          ).toFixed(2)}
                    </span>
                  )}
                </div>
                {foundprod ? (
                  <button
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    onClick={() => moveToCart(i)}
                    className="w-100">
                    {/* <img src={cartIcon} alt="Icon" className="AddToProd" /> */}
                    <span>Product added to cart</span>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      moveToCart(i);
                    }}
                    className="w-100 MoveToCart ">
                    {/* <img src={cartIcon} alt="Icon" className="AddToProd " /> */}
                    <span>MOVE TO CART</span>
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
