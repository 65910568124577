import { useContext } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import { Tooltip } from "@nextui-org/react";
import moment from "moment";
import { ChatContext } from "../../Contexts/ChatContext";

require("moment-timezone");

export default function DealRegular(props) {
  const { setRegularProduct } = useContext(ChatContext);
  console.log(props);
  let DiscountAmount = props?.deal?.dealInfo?.REGULAR?.getOff?.numericAmount;
  let DiscountType = props?.deal?.dealInfo?.REGULAR?.getOff?.type;
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const endsOn = props?.deal?.outletBasisConfig[chainID].endsOn;
  const endDate = moment.tz(moment(endsOn), props.timeZone);
  const duration = moment.duration(endDate.diff(moment()));
  const daysLeft = Math.ceil(duration.asDays());

  let timeLeft;
  if (daysLeft === 1) {
    timeLeft = "1 day left";
  } else {
    timeLeft = `${daysLeft} days left`;
  }

  let currency = props?.product?.variants[0]?.pricingInfo?.currency
    ? props?.product?.variants[0]?.pricingInfo?.currency === "USD"
      ? "$"
      : props?.product?.variants[0]?.pricingInfo?.currency + "$"
    : "$";

  let discountedPrice;
  if (DiscountType === "PERCENTAGE") {
    discountedPrice =
      props?.product?.variants[0]?.price -
      (props?.product?.variants[0]?.price * DiscountAmount) / 100;
  } else {
    discountedPrice = props?.product?.variants[0]?.price * DiscountAmount;
  }

  const setproductInstorage = () => {
    // localStorage.setItem("regularProduct", JSON.stringify(props?.deal));
    setRegularProduct(props?.deal);
  };
  return (
    <div>
      <Card
        style={{
          margin: "20px",
          width: "270px",
          height: "360px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
        }}>
        <div className="dealCard-body">
          {props.deal?.outletBasisConfig[chainID].neverExpires ? null : (
            <div className="deals-endsin-div">
              <div className="deals-endsin">{timeLeft}</div>
            </div>
          )}
          <div className="dealCard-img">
            {props.product.product?.images ? (
              <img src={props.product.product?.images} alt="dealimg" />
            ) : (
              <img src={noimg} alt="dealimg" />
            )}
          </div>

          <div className="regularDeal-priceDiv">
            <div style={{ display: "flex" }}>
              <span className="regularDeal-Disprice">
                {currency + discountedPrice?.toFixed(2)}
              </span>
              <span className="regularDeal-price">
                <s>
                  {currency + props?.product?.variants[0]?.price?.toFixed(2)}
                </s>
              </span>
            </div>
            <div className="regularDeal-off">
              ({DiscountType !== "PERCENTAGE" ? currency : null}
              {DiscountAmount} {DiscountType === "PERCENTAGE" ? "%" : null} Off)
            </div>
          </div>

          <span className="regularDeal-name">
            {props.product.product?.name}
          </span>

          <div>
            {
              <Link
                to={"/product/" + props?.product?.productID}
                style={{ textDecoration: "none" }}>
                <div
                  style={{ width: "100%" }}
                  className="veiw-product-btn-home cursor"
                  onClick={() => {
                    setproductInstorage();
                  }}>
                  View Product
                </div>
              </Link>
            }
          </div>
        </div>
      </Card>
    </div>
  );
}
