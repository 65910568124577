import { camelCase, startCase } from "lodash";
import { useHistory } from "react-router-dom";

//import itemimg from "../../images/preroll-stock-2-v1 2.png";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";

function ItemCard(props) {
  const history = useHistory();

  const redirect = () => {
    history.push("/product/" + props?.productID);
    window.location.reload(false);
  };

  console.log(
    props?.orderDetials?.addedProduct?.finalPricingInfo
      ?.finalApplicableTotalPrice
  );

  return (
    <div
      onClick={() => {
        redirect();
      }}
      style={{ cursor: "pointer", width: "100%" }}>
      {/* ************************************* Mobile Veiw ********************************** */}
      <div className="item  hide-order">
        <div className="row" style={{ width: "100%", marginTop: "20px" }}>
          <div className="col-10" style={{ display: "flex", padding: "0px" }}>
            {props.image ? (
              <img
                style={{ width: "73px", height: "73px" }}
                src={props.image}
                alt=""
              />
            ) : (
              <img
                style={{ width: "73px", alignSelf: "center" }}
                src={noimg}
                alt=""
              />
            )}
            {props?.name?.length <= 40 ? (
              <p>{startCase(camelCase(props.name))}</p>
            ) : (
              <p>{startCase(camelCase(props?.name?.substring(0, 40)))}...</p>
            )}
          </div>

          <div className="col-2 order-qtydiv">
            <span className="order-label">Qty:</span>
            <span className="order-label-value">{props.qty}</span>
          </div>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col order-qtydiv">
            <span className="order-label">Status:</span>
            <span className="status">
              {props.status === "New" ? (
                <span className="New-status">{props.status}</span>
              ) : props.status === "Pending" ? (
                <span className="Pending-status">{props.status}</span>
              ) : props.status === "Completed" ? (
                <span className="Completed-status">{props.status}</span>
              ) : (
                "N/A"
              )}
            </span>
          </div>

          <div className="col order-qtydiv">
            <span className="order-label">Total Amount:</span>
            <span className="order-totalAmount">
              {(
                (props?.orderDetials?.addedProduct?.finalPricingInfo
                  ?.finalApplicableTotalPrice
                  ? props?.orderDetials?.addedProduct?.finalPricingInfo
                      ?.finalApplicableTotalPrice + props?.price
                  : props?.price) * props?.qty
              )?.toFixed(2)}
            </span>
          </div>
        </div>

        {/* <button>WRITE REVIEW</button> */}
      </div>

      {/* ************************************* WEB Veiw *********************************** */}
      <div className="item  hide-web-order">
        <div className="row" style={{ width: "100%", marginTop: "20px" }}>
          <div className="col-6" style={{ display: "flex" }}>
            {props.image ? (
              <img
                style={{ width: "73px", height: "73px" }}
                src={props.image}
                alt=""
              />
            ) : (
              <img
                style={{ width: "73px", alignSelf: "center" }}
                src={noimg}
                alt=""
              />
            )}
            <div>
              {props?.name?.length <= 40 ? (
                <p>{startCase(camelCase(props.name))}</p>
              ) : (
                <p>{startCase(camelCase(props?.name?.substring(0, 40)))}...</p>
              )}
              {props.selectedVariantData?.weight ? (
                <span
                  className="cartVarient"
                  style={{ color: "#121212", marginLeft: "25px" }}>
                  {props.selectedVariantData?.weight}{" "}
                  {props.selectedVariantData?.uom}
                </span>
              ) : null}
            </div>
          </div>

          <div className="col order-qtydiv">
            <span className="order-label">Qty:</span>
            <span className="order-label-value">{props.qty}</span>
          </div>

          <div className="col order-qtydiv">
            <span className="order-label">Total Amount:</span>
            <span className="order-totalAmount">
              {(
                (props?.orderDetials?.addedProduct?.finalPricingInfo
                  ?.finalApplicableTotalPrice
                  ? props?.orderDetials?.addedProduct?.finalPricingInfo
                      ?.finalApplicableTotalPrice + props?.price
                  : props?.price) * props?.qty
              )?.toFixed(2)}
            </span>
          </div>

          <div className="col order-qtydiv">
            <span className="order-label">Status:</span>
            <span className="status">
              {props.status === "New" ? (
                <span className="New-status">{props.status}</span>
              ) : props.status === "Pending" ? (
                <span className="Pending-status">{props.status}</span>
              ) : props.status === "Completed" ? (
                <span className="Completed-status">{props.status}</span>
              ) : (
                "N/A"
              )}
            </span>
          </div>
        </div>

        {/* <button>WRITE REVIEW</button> */}
      </div>
    </div>
  );
}

export default ItemCard;
