import React from "react";
import AppDetial from "../../components/AppDetials/AppDetial";

export default function AppDetialPage() {
  return (
    <div style={{ width: "100%" }}>
      <AppDetial />
    </div>
  );
}
